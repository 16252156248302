import React, { Fragment, useState } from "react";
import {
  List,
  useRedirect,
  useUpdate,
  TopToolbar,
  Filter,
  TabbedForm,
  TabbedShowLayout,
  Tab,
  FormTab,
  ReferenceArrayField,
  SingleFieldList,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  Button,
  Confirm,
  useUpdateMany,
  useRefresh,
  useNotify,
  useUnselectAll,
  Pagination,
  BulkDeleteButton,
  UrlField,
  Show,
  Create,
  EditButton,
  Datagrid,
  BooleanField,
  TextField,
  TextInput,
  ReferenceField,
  BooleanInput,
  ReferenceInput,
  AutocompleteInput,
  Edit,
  SimpleForm,
  FunctionField,
  NumberInput,
  ImageField,
  DateInput,
  DateField,
} from "react-admin";

const BigPagination = (props) => (
  <Pagination rowsPerPageOptions={[25, 50, 100, 2000]} {...props} />
);

const ApproveTimes = ({ selectedIds, resource }) => {
  const [open, setOpen] = useState(false);
  const refresh = useRefresh();
  const notify = useNotify();
  const unselectAll = useUnselectAll();
  const [updateMany, { loading }] = useUpdateMany(
    "Times",
    selectedIds,
    { approved: true },
    {
      onSuccess: () => {
        refresh();
        notify("Times approved!");
        unselectAll(resource);
      },
      onFailure: () => notify("Error: Times not approved", "warning"),
    }
  );
  const handleClick = () => setOpen(true);
  const handleDialogClose = () => setOpen(false);

  const handleConfirm = () => {
    updateMany();
    setOpen(false);
  };
  return (
    <>
      <Button label='Approve' onClick={handleClick} />
      <Confirm
        isOpen={open}
        loading={loading}
        title='Approve Times'
        content='Are you sure you want to approve these times?'
        onConfirm={handleConfirm}
        onClose={handleDialogClose}
      />
    </>
  );
};

const DenyTimes = ({ selectedIds, resource }) => {
  const [open, setOpen] = useState(false);
  const refresh = useRefresh();
  const notify = useNotify();
  const unselectAll = useUnselectAll();
  const [updateMany, { loading }] = useUpdateMany(
    "Times",
    selectedIds,
    { approved: false },
    {
      onSuccess: () => {
        refresh();
        notify("Times denied!");
        unselectAll(resource);
      },
      onFailure: () => notify("Error: Times not denied", "warning"),
    }
  );
  const handleClick = () => setOpen(true);
  const handleDialogClose = () => setOpen(false);

  const handleConfirm = () => {
    updateMany();
    setOpen(false);
  };

  return (
    <>
      <Button label='Deny' onClick={handleClick} />
      <Confirm
        isOpen={open}
        loading={loading}
        title='Deny Times'
        content='Are you sure you want to deny these times?'
        onConfirm={handleConfirm}
        onClose={handleDialogClose}
      />
    </>
  );
};

const ApproveButton = ({ record }) => {
  const refresh = useRefresh();
  const notify = useNotify();
  const redirect = useRedirect();
  const [approve, { loading }] = useUpdate(
    "Times",
    record ? record.id : null,
    { approved: true },
    {
      onSuccess: () => {
        console.log("success");
        redirect("/drops");
        refresh();
        notify("Time approved!");
      },
      onFailure: (error) => notify(`Error: ${error.message}`, "warning"),
    }
  );
  return <Button label='Approve' onClick={approve} disabled={loading} />;
};

const DenyButton = ({ record }) => {
  const refresh = useRefresh();
  const notify = useNotify();
  const redirect = useRedirect();
  const [Deny, { loading }] = useUpdate(
    "Times",
    record ? record.id : null,
    { approved: false },
    {
      onSuccess: () => {
        console.log("success");
        redirect("/drops");
        refresh();
        notify("Time denied!");
      },
      onFailure: (error) => notify(`Error: ${error.message}`, "warning"),
    }
  );
  return <Button label='Deny' onClick={Deny} disabled={loading} />;
};

const PostBulkActionButtons = (props) => (
  <>
    <ApproveTimes label='Approve Times' {...props} />
    <DenyTimes label='Deny Times' {...props} />
    {/* default bulk delete action */}
    <BulkDeleteButton {...props} />
  </>
);

const ShowActions = ({ basePath, data }) => (
  <TopToolbar>
    <EditButton basePath={basePath} record={data} />
    <ApproveButton basePath={basePath} record={data} />
    <DenyButton basePath={basePath} record={data} />
  </TopToolbar>
);

const SearchFilter = (props) => (
  <Filter {...props}>
    <TextInput source='record' alwaysOn />
    <ReferenceInput
      label='Admin'
      source='admin'
      reference='members'
      alwaysOn
      filter={{ Clan_id: props.clan.id }}
    >
      <AutocompleteInput optionText='username' />
    </ReferenceInput>
    <ReferenceInput
      label='Submitter'
      source='submitter'
      reference='members'
      filter={{ Clan_id: props.clan.id }}
      alwaysOn
    >
      <AutocompleteInput
        optionText='username'
        allowEmpty
        emptyText='-- none --'
      />
    </ReferenceInput>
    <DateInput source='date' alwaysOn />
  </Filter>
);

export const TimeList = (props) => (
  <List
    {...props}
    pagination={<BigPagination />}
    perPage={25}
    filters={<SearchFilter {...props} />}
    bulkActionButtons={<PostBulkActionButtons {...props} />}
    filter={{ Clan_id: props.clan.id }}
  >
    <Datagrid rowClick='show'>
      <FunctionField
        label='Time'
        render={(record) =>
          `${parseInt(record.value / 60)}:${parseInt(record.value % 60)}`
        }
      />
      <ImageField source='screenshot' />
      <ReferenceField label='Record' source='Record_id' reference='Records'>
        <TextField source='record' />
      </ReferenceField>
      <BooleanField source='approved' />
      <ReferenceField label='Submitter' source='submitter' reference='members'>
        <TextField source='username' />
      </ReferenceField>
      <ReferenceField label='Admin' source='admin' reference='members'>
        <TextField source='username' />
      </ReferenceField>
      <DateField source='date' />
    </Datagrid>
  </List>
);

export const TimeShow = (props) => (
  <Show {...props} actions={<ShowActions />}>
    <TabbedShowLayout>
      <Tab label='Time'>
        <FunctionField
          label='Time'
          render={(record) =>
            `${parseInt(record.value / 60)}:${parseInt(record.value % 60)}`
          }
        />
        <UrlField source='screenshot' />
        <ReferenceField label='Record' source='Record_id' reference='Records'>
          <TextField source='record' />
        </ReferenceField>
        <BooleanField source='approved' />
        <DateField source='date' />
        <ReferenceField label='Admin' source='admin' reference='members'>
          <TextField source='username' />
        </ReferenceField>
      </Tab>
      <Tab label='Members' path='Members'>
        <ReferenceArrayField source='members' reference='members'>
          <SingleFieldList>
            <TextField source='username' />
          </SingleFieldList>
        </ReferenceArrayField>
      </Tab>
    </TabbedShowLayout>
  </Show>
);

export const TimeEdit = (props) => (
  <Edit {...props} undoable={false}>
    <TabbedForm>
      <FormTab label='event'>
        <TextInput label='Time (seconds)' source='value' />
        <TextInput source='screenshot' />
        <ReferenceInput
          label='Record'
          source='Record_id'
          reference='Records'
          filter={{ Clan_id: props.clan.id }}
        >
          <AutocompleteInput optionText='record' />
        </ReferenceInput>
        <BooleanInput source='approved' />
        <DateInput source='date' />
      </FormTab>
      <FormTab label='Members' path='Members'>
        <ReferenceArrayInput
          source='members'
          reference='members'
          filter={{ Clan_id: props.clan.id }}
        >
          <AutocompleteArrayInput optionText='username' />
        </ReferenceArrayInput>
      </FormTab>
    </TabbedForm>
  </Edit>
);

export const TimeCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput label='Time (seconds)' source='value' />
      <TextInput source='screenshot' />
      <ReferenceInput
        label='Record'
        source='Record_id'
        reference='Records'
        filter={{ Clan_id: props.clan.id }}
      >
        <AutocompleteInput optionText='record' />
      </ReferenceInput>
      <BooleanInput source='approved' />
      <DateInput source='date' defaultValue={new Date().toISOString()} />
      <NumberInput source='Clan_id' initialValue={props.clan.id} disabled />
    </SimpleForm>
  </Create>
);
