import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { Item } from "../../models";
import { Box, Grid, Card, Typography, CardContent } from "@mui/material";

interface ItemCardProps {
  item: Item;
}

const ItemCard: FunctionComponent<ItemCardProps> = ({ item }) => {
  const { t } = useTranslation();

  return (
    <Grid container justifyContent="center" spacing={2}>
      <Grid item xs={10} sm={10} md={10}>
        <Card>
          <CardContent sx={{ "&:last-child": { paddingBottom: "15px" } }}>
            <Grid container justifyContent="center">
              <Grid item xs={12} sm={12} md={6} justifyContent="center" alignItems="center" sx={{ margin: "auto" }}>
                <Box
                  sx={{
                    width: "50px",
                    minHeight: "50px",
                    height: "100%",
                    backgroundImage: `url(${item.artwork})`,
                    backgroundPosition: "center",
                    backgroundSize: "contain",
                    backgroundRepeat: "no-repeat",
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <Typography variant="h5" component="h5">
                  {item.name}
                </Typography>
                <Typography variant="overline" component="p">
                  {t("items.itemCard.attack")}: {item.attack}
                </Typography>
                {item.tank && item.tank > 0 ? (
                  <Typography variant="overline" component="p">
                    {t("items.itemCard.tank")}: {item.tank}
                  </Typography>
                ) : null}
                {item.alt && item.alt > 0 ? (
                  <Typography variant="overline" component="p">
                    {t("items.itemCard.alt")}: {item.alt}
                  </Typography>
                ) : null}
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default ItemCard;
