import React from "react";
import {
  Filter,
  Pagination,
  List,
  Show,
  Create,
  Datagrid,
  TextField,
  TextInput,
  NumberField,
  NumberInput,
  Edit,
  SimpleForm,
  TabbedShowLayout,
  ReferenceField,
  ReferenceInput,
  AutocompleteInput,
  Tab,
  ReferenceManyField,
  DateField,
  BooleanField,
  TopToolbar,
  EditButton,
  BooleanInput,
} from "react-admin";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";

const BigPagination = (props) => (
  <Pagination rowsPerPageOptions={[25, 50, 100, 2000]} {...props} />
);
const SearchFilter = (props) => (
  <Filter {...props}>
    <TextInput source="name" alwaysOn />
  </Filter>
);
const ShowActions = ({ basePath, data }) => (
  <TopToolbar>
    <AddTaskButton record={data} />
    <AddStudentButton record={data} />
    <EditButton basePath={basePath} record={data} />
  </TopToolbar>
);
const AddStudentButton = ({ record }) => (
  <Button
    component={Link}
    to={{
      pathname: "/Students/create",
      state: {
        record: {
          active: false,
          MentorProgram_id: record && record.id,
          joinDate: new Date(),
        },
      },
    }}
  >
    Add student
  </Button>
);

const AddTaskButton = ({ record }) => (
  <Button
    component={Link}
    to={{
      pathname: "/Tasks/create",
      state: {
        record: {
          approved: false,
          Checklist_id: record && record.Checklist_id,
        },
      },
    }}
  >
    Add task
  </Button>
);

export const MentorProgramList = (props) => (
  <List
    {...props}
    pagination={<BigPagination />}
    perPage={25}
    filters={<SearchFilter {...props} />}
    filter={{ Clan_id: props.clan.id }}
  >
    <Datagrid rowClick="show">
      <TextField source="name" />
      <TextField source="description" />
      <TextField source="artwork" />
      <NumberField source="pointsPerMentor" />
      <NumberField source="pointsPerHelper" />
      <NumberField source="pointsPerStudent" />
      <ReferenceField
        label="Checklist"
        source="Checklist_id"
        reference="Checklists"
      >
        <TextField source="name" />
      </ReferenceField>
      <BooleanField source="active" />
    </Datagrid>
  </List>
);

export const MentorProgramShow = (props) => (
  <Show {...props} actions={<ShowActions />}>
    <TabbedShowLayout>
      <Tab label="Mentor Program">
        <TextField source="name" />
        <TextField source="description" />
        <TextField source="artwork" />
        <NumberField source="pointsPerMentor" />
        <NumberField source="pointsPerHelper" />
        <NumberField source="pointsPerStudent" />
        <ReferenceField
          label="Checklist"
          source="Checklist_id"
          reference="Checklists"
        >
          <TextField source="name" />
        </ReferenceField>
        <BooleanField source="active" />
        <BooleanField source="autoEnroll" />
        <TextField source="studentRoleId" />
        <TextField source="graduateRoleId" />
      </Tab>
      <Tab label="Teachers">
        <ReferenceManyField
          target="MentorProgram_id"
          reference="Teachers"
          label="Teachers"
          pagination={
            <Pagination rowsPerPageOptions={[10, 25, 50, 100, 2000]} />
          }
        >
          <Datagrid rowClick="show">
            <ReferenceField
              label="Member"
              source="Member_id"
              reference="members"
              link={false}
            >
              <TextField source="username" />
            </ReferenceField> 
            <ReferenceField
              label="Timezone"
              source="Member_id"
              reference="members"
              link={false}
            >
              <TextField source="timezone" />
            </ReferenceField>
          </Datagrid>
        </ReferenceManyField>
      </Tab>
      <Tab label="Students">
        <ReferenceManyField
          target="MentorProgram_id"
          reference="Students"
          label="Students"
          pagination={
            <Pagination rowsPerPageOptions={[10, 25, 50, 100, 2000]} />
          }
        >
          <Datagrid rowClick="show">
            <ReferenceField
              label="Member"
              source="Member_id"
              reference="members"
              link={false}
            >
              <TextField source="username" />
            </ReferenceField>
            <ReferenceField
              label="Timezone"
              source="Member_id"
              reference="members"
              link={false}
            >
              <TextField source="timezone" />
            </ReferenceField>
            <BooleanField label="Active" source="active" />
            <BooleanField label="Graduated" source="graduated" />
            <DateField label="Student Join Date" source="joinDate" />
          </Datagrid>
        </ReferenceManyField>
      </Tab>
      <Tab label="Sessions">
        <ReferenceManyField
          target="MentorProgram_id"
          reference="Mentors"
          label="Sessions"
        >
          <Datagrid>
            <TextField source="name" />
            <DateField source="date" />
            <NumberField source="completions" />
            <ReferenceField label="Host" source="Member_id" reference="members">
              <TextField source="username" />
            </ReferenceField>
            <BooleanField source="approved" />
            <ReferenceField
              label="Submitter"
              source="submitter"
              reference="members"
            >
              <TextField source="username" />
            </ReferenceField>
          </Datagrid>
        </ReferenceManyField>
      </Tab>
      <Tab label="Tasks">
        <ReferenceManyField
          source="Checklist_id"
          target="Checklist_id"
          reference="Tasks"
          label="Tasks"
        >
          <Datagrid rowClick="show">
            <TextField source="name" />
            <TextField source="description" />
            <TextField source="artwork" />
            <ReferenceField
              label="Checklist"
              source="Checklist_id"
              reference="Checklists"
            >
              <TextField source="name" />
            </ReferenceField>
            <ReferenceField
              label="Autocompleted by"
              source="parenttask"
              reference="Tasks"
            >
              <TextField source="name" />
            </ReferenceField>
            <ReferenceField label="Badge" source="Badge_id" reference="Badges">
              <TextField source="name" />
            </ReferenceField>
          </Datagrid>
        </ReferenceManyField>
      </Tab>
    </TabbedShowLayout>
  </Show>
);

export const MentorProgramEdit = (props) => (
  <Edit {...props} undoable={false}>
    <SimpleForm>
      <TextInput source="name" />
      <TextInput source="description" />
      <TextInput source="artwork" />
      <NumberInput source="pointsPerMentor" />
      <NumberInput source="pointsPerHelper" />
      <NumberInput source="pointsPerStudent" />
      <ReferenceInput
        label="Checklist"
        source="Checklist_id"
        reference="Checklists"
        filter={{ Clan_id: props.clan.id }}
      >
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
      <BooleanInput source="active" />
      <BooleanInput source="autoEnroll" />
      <TextInput source="studentRoleId" />
      <TextInput source="graduateRoleId" />
    </SimpleForm>
  </Edit>
);

export const MentorProgramCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="name" />
      <TextInput source="description" />
      <TextInput source="artwork" />
      <NumberInput source="pointsPerMentor" />
      <NumberInput source="pointsPerHelper" />
      <NumberInput source="pointsPerStudent" />
      <ReferenceInput
        label="Checklist"
        source="Checklist_id"
        reference="Checklists"
        filter={{ Clan_id: props.clan.id }}
      >
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
      <BooleanInput source="active" />
      <BooleanInput source="autoEnroll" />
      <TextInput source="studentRoleId" />
      <TextInput source="graduateRoleId" />
      <NumberInput source="Clan_id" initialValue={props.clan.id} disabled />
    </SimpleForm>
  </Create>
);
