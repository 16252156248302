import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import React from "react";
import Lottie from "react-lottie";
import Cookies from "universal-cookie";
import { useTranslation } from 'react-i18next';
import pepe from "./loading.json";
import tea from "./tea.json";
import head from "./head.json";
import spider from "./spider.json";
import hug from "./hugging.json";
import wink from "./wink.json";

const indicators = {
  tea,
  head,
  spider,
  pepe,
  hug,
  wink,
};

export default function Loading() {
  const { t } = useTranslation();
  const cookies = new Cookies();

  return (
    <Paper>
      <Grid
        container
        direction="column"
        justify="flex-end"
        alignItems="center"
      >
        <Typography variant="h3" align="center" gutterBottom>
          {t('global.loading')}
        </Typography>
        <Lottie
          options={{
            loop: true,
            autoplay: true,
            animationData: cookies.get("indicator", { path: "/" })
              ? indicators[cookies.get("indicator", { path: "/" })]
              : pepe,
            rendererSettings: { preserveAspectRatio: "xMidYMid slice" },
          }}
          height={300}
          width={300}
          isStopped={false}
          isPaused={false}
        />
      </Grid>
    </Paper>
  );
}
