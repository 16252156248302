import React, { useContext } from "react";
import Drawer from "@mui/material/Drawer";
import Hidden from "@mui/material/Hidden";
import Box from "@mui/material/Box";
import { UserContext } from "../../contexts/userContext";
import { ClanContext } from "../../contexts/clanContext";
import { ThemeContext } from "../../contexts/themeContext";
import Social from "./Social";
import Member from "./Member";
import Admin from "./Admin";
import Hiscores from "./Hiscore";
import Submissions from "./Submissions";

const drawerWidth = 260;

export default function Drawers({ open, handleDrawerToggle }) {
  const { user } = useContext(UserContext);
  const { clan } = useContext(ClanContext);
  const { mode } = useContext(ThemeContext);

  const baseUrl =
    !process.env.REACT_APP_STANDALONE_ID && clan?.id ? `/${clan.slug}` : "";
  const isMentorStaff =
    user && user.teacherPrograms && user.teacherPrograms.length > 0;

  const drawerStyles = {
    width: drawerWidth,
    flexShrink: 0,
    "& .MuiDrawer-paper": {
      width: drawerWidth,
      height: "100vh",
      mt: { xs: "52px", sm: "64px" },
      overflowY: "visible",
      borderRight: 0,
      textAlign: "left",
      pb: "40px",
      px: { xs: 0, sm: 2 },
      boxShadow:
        "0 0px 0px 0px rgba(0, 0, 0, 0.12), 0 7px 10px -5px rgba(0, 0, 0, 0.15)",
      transition: "top 0.2s linear, bottom 0.2s linear, width 0.35s ease",
    },
  };

  return (
    <Box sx={{
      backgroundColor: mode === "dark" ? "#1e1e1e" : "#eaeaea",
    }}>
      <Hidden smUp>
        <Drawer
          variant="temporary"
          anchor="right"
          open={open}
          onClose={handleDrawerToggle}
          sx={drawerStyles}
          elevation={-2}
        >
          <Box
            sx={{
              height: "100%",
              backgroundColor: mode === "dark" ? "#1e1e1e" : "#eaeaea",
              display: "flex",
              flexDirection: "column",
              px: 2,
            }}
          >
            <div
              style={{
                maxHeight: "100vh",
                overflow: "auto",
                scrollbarWidth: "none",
                msOverflowStyle: "none",
              }}
            >
              {clan.id && <Member baseUrl={baseUrl} />}
              {user?.id && user.Clan_id === clan.id && (
                <Submissions baseUrl={baseUrl} />
              )}
              {clan.id && <Hiscores baseUrl={baseUrl} />}
              {clan.id && <Social baseUrl={baseUrl} />}
              {user &&
                (user.isAdmin || user.isEventStaff || isMentorStaff) &&
                user.Clan_id === clan.id && <Admin baseUrl={baseUrl} />}
            </div>
          </Box>
        </Drawer>
      </Hidden>

      <Hidden smDown>
        <Drawer variant="permanent" anchor="left" sx={drawerStyles}>
          <div
            style={{
              maxHeight: "100vh",
              overflow: "auto",
              scrollbarWidth: "none",
              msOverflowStyle: "none",
            }}
          >
            {clan.id && <Member baseUrl={baseUrl} />}
            {user?.id && user.Clan_id === clan.id && (
              <Submissions baseUrl={baseUrl} />
            )}
            {clan.id && <Hiscores baseUrl={baseUrl} />}
            {clan.id && <Social baseUrl={baseUrl} />}
            {user &&
              (user.isAdmin || user.isEventStaff || isMentorStaff) &&
              user.Clan_id === clan.id && <Admin baseUrl={baseUrl} />}
          </div>
        </Drawer>
      </Hidden>
    </Box>
  );
}
