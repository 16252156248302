import React from "react";
import Card from "@material-ui/core/Card";
import { CardContent } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Icon from "@material-ui/core/Icon";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import { Card as Card5, } from "@mui/material";

export default function BadgeBar({ badges, title }) {
  const isValidUrl = (string) => {
    try {
      new URL(string);
    } catch (_) {
      return false;
    }
    return true;
  };
  const useStyles = makeStyles(() => ({
    customBadge: {
      height: "30px",
      width: "30px",
      backgroundPosition: "center",
      backgroundSize: "contain",
      backgroundRepeat: "no-repeat",
    },
  }));

  const classes = useStyles();

  return badges.length > 0 && (
    <Grid item xs={12}>
      <Card5 key={title}>
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography>{title}</Typography>
            </Grid>
            {badges.map((badge) => (
              <Grid key={badge.id} item xs={6} sm={6} md={4} lg={2}>
                <Tooltip
                  title={badge.description}
                  placement={window.innerWidth > 959 ? "top" : "left"}
                >
                  <Card>
                    <CardContent>
                      <Grid container spacing={1}>
                        <Grid container item xs={12} justify="center">
                          {isValidUrl(badge.icon) ? (
                            <div
                            style={{
                              backgroundImage: `url('${badge.icon}')`,
                            }}
                            src={badge.icon}
                            className={classes.customBadge}
                            />
                            ) : (
                              <Icon style={{ fontSize: 30 }}>{badge.icon}</Icon>
                              )}
                        </Grid>
                        <Grid container item xs={12} justify="center">
                          <Typography variant="caption">{badge.name}</Typography>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Tooltip>
              </Grid>
            ))}
          </Grid>
        </CardContent>
      </Card5>
    </Grid>
  );
}
