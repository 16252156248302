import React, { useContext, FunctionComponent, useEffect } from "react";
import Button from "@material-ui/core/Button";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { useTranslation } from "react-i18next";
import CenteredGrid from "../../components/Shared/CenteredGrid";
import Layout from "../../components/Layout/Layout";
import RecordFormat from "../../components/NumberFormat/RecordFormat";
import { ThemeContext } from "../../contexts/themeContext";
import { UserContext } from "../../contexts/userContext";
import { ClanContext } from "../../contexts/clanContext";
import { SubmissionContext } from "../../contexts/submissionContext";
import { Member, Boss, Time, Record } from "../../models";

const RecordLogger: FunctionComponent = () => {
  const { t } = useTranslation();
  const { clan } = useContext(ClanContext);
  const {
    members,
    fetchMembers,
    createRecord,
    bosses,
    records,
    fetchRecords,
    recordsFetched,
    fetchBosses,
  } = useContext(SubmissionContext);
  const [value, setValue] = React.useState("");
  const [record, setRecord] = React.useState({} as Record);
  const [boss, setBoss] = React.useState({} as Boss);
  const [helpers, setHelpers] = React.useState([] as Array<Member>);
  const [screenshot, setScreenshot] = React.useState("");
  const { recordSubmitted, setRecordSubmitted, submitting } = useContext(ThemeContext);
  const { user } = useContext(UserContext);
  const resetForm = () => {
    setScreenshot("");
    setHelpers([] as Array<Member>);
    setBoss({} as Boss);
    setValue("");
    setRecord({} as Record);
    setRecordSubmitted(false);
  };

  const recordObject = {
    Record_id: record.id,
    screenshot,
    submitter: user.id,
    Clan_id: clan.id,
    members: helpers.map((helper) => helper.id),
    date: new Date(),
    value:
      value &&
      parseInt(value.substring(0, 2)) * 3600 +
        parseInt(value.substring(2, 4)) * 60 +
        parseInt(value.substring(4, 6)),
  } as Time;

  useEffect(() => {
    if (clan && clan.id && !recordsFetched) {
      fetchRecords();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recordsFetched]);

  return (
    <Layout
      loading={!recordsFetched}
      title={t("recordLogger.title")}
      background={boss.artwork || clan.clanBackgroundImage}
      helperText={t("recordLogger.helperText")}
    >
      {!recordSubmitted ? (
        <CenteredGrid container spacing={1}>
          <>
            <Grid item xs={12}>
              <Autocomplete
                options={bosses.filter((x) =>
                  [...new Set(records.map((x) => x.Boss_id))].includes(x.id)
                )}
                autoHighlight
                autoSelect
                getOptionLabel={(option) => option.name || ""}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    autoFocus
                    label={t("recordLogger.bossLabel")}
                    placeholder={t("recordLogger.bossPlaceholder")}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                )}
                onChange={(event, boss) => {
                  setBoss(boss || ({} as Boss));
                }}
                onInputChange={(event, value) => {
                  fetchBosses(value);
                }}
              />
            </Grid>
            {boss.id && (
              <>
                <Grid item xs={12}>
                  <Autocomplete
                    options={[
                      ...new Set(
                        records
                          .filter((time) => time.Boss_id === boss.id)
                          .map((x) => x.record)
                      ),
                    ].map((record) => {
                      const time = records.find(
                        (time) =>
                          time.Boss_id === boss.id && time.record === record
                      );
                      return time;
                    })}
                    autoHighlight
                    autoSelect
                    getOptionLabel={(option) =>
                      option ? option.record || "" : ""
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        autoFocus
                        label={t("recordLogger.recordLabel")}
                        placeholder={t("recordLogger.recordPlaceholder")}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    )}
                    onChange={(event, record) => {
                      setRecord(record || {} as Record);
                    }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    id='value'
                    label={t("recordLogger.valueLabel")}
                    placeholder={t("recordLogger.valuePlaceholder")}
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={value}
                    onChange={(e) => setValue(e.target.value)}
                    name='Value'
                    InputProps={{
                      inputComponent: RecordFormat,
                    }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Autocomplete
                    multiple
                    options={members}
                    autoHighlight
                    autoSelect
                    getOptionLabel={(option) => option.username || ""}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        label={t("recordLogger.membersLabel")}
                        placeholder={t("recordLogger.membersPlaceholder")}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    )}
                    onChange={(event, helpers) => {
                      setHelpers(helpers || {});
                    }}
                    onInputChange={(event, value) => {
                      fetchMembers(value);
                    }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    id='screenshot'
                    label={t("recordLogger.screenshotLabel")}
                    placeholder={t("recordLogger.screenshotPlaceholder")}
                    fullWidth
                    value={screenshot}
                    onChange={(e) => setScreenshot(e.target.value)}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
              </>
            )}
            <Grid item xs={12}>
              <Button
                disabled={
                  !boss.id ||
                  !screenshot ||
                  !value ||
                  !record.id ||
                  members.length < 1 ||
                  submitting
                }
                variant='outlined'
                onClick={() => createRecord(recordObject)}
              >
                {t("recordLogger.submit")}
              </Button>
            </Grid>
          </>
        </CenteredGrid>
      ) : (
        <CenteredGrid container spacing={1}>
          <>
            <Grid item xs={12}>
              <Typography variant='caption'>
                {t("recordLogger.success")}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Button variant='outlined' onClick={() => resetForm()}>
                {t("recordLogger.submitAnother")}
              </Button>
            </Grid>
          </>
        </CenteredGrid>
      )}
    </Layout>
  );
};

export default RecordLogger;
