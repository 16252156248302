import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

export default function InfoArea({ title, description, Icon }) {
  return (
    <Grid container justifyContent="center">
      <Grid item xs={10} sm={10} md={10}>
        <Icon />
      </Grid>
      <Grid item xs={10} sm={10} md={10}>
        <Typography variant="h5">{title}</Typography>
      </Grid>
      <Grid item xs={10} sm={10} md={10}>
        <Typography variant="body1">{description}</Typography>
      </Grid>
    </Grid>
  );
}
