import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    NumberField,
    ReferenceField,
    Pagination,
    ReferenceInput,
    SelectInput,
    Filter,
    TextInput,
    Show,
    TabbedShowLayout,
    Tab,
    Create,
    SimpleForm,
    NumberInput,
    Edit
} from 'react-admin';


const BingoProgressFilter = (props) => (
    <Filter {...props}>
        <ReferenceInput label="Tile" source="BingoTile_id" reference="BingoTiles">
            <SelectInput optionText="description" />
        </ReferenceInput>
        <ReferenceInput label="Team" source="BingoTeam_id" reference="BingoTeams">
            <SelectInput optionText="name" />
        </ReferenceInput>
    </Filter>
);

const BigPagination = (props) => (
    <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />
);

export const BingoProgressList = (props) => (
    <List {...props} filters={<BingoProgressFilter />} pagination={<BigPagination />} perPage={25}>
        <Datagrid rowClick="show">
            <ReferenceField label="Tile" source="BingoTile_id" reference="BingoTiles">
                <TextField source="description" />
            </ReferenceField>
            <ReferenceField label="Team" source="BingoTeam_id" reference="BingoTeams">
                <TextField source="name" />
            </ReferenceField>
            <NumberField source="goal_count" />
            <NumberField source="current_count" />
            <TextField source="status" />
        </Datagrid>
    </List>
);

export const BingoProgressShow = (props) => (
    <Show {...props}>
        <TabbedShowLayout>
            <Tab label="Details">
                <ReferenceField label="Tile" source="BingoTile_id" reference="BingoTiles">
                    <TextField source="description" />
                </ReferenceField>
                <ReferenceField label="Team" source="BingoTeam_id" reference="BingoTeams">
                    <TextField source="name" />
                </ReferenceField>
                <NumberField source="goal_count" />
                <NumberField source="current_count" />
                <TextField source="status" />
            </Tab>
        </TabbedShowLayout>
    </Show>
);

export const BingoProgressCreate = (props) => (
    <Create {...props}>
        <SimpleForm>
            <ReferenceInput label="Tile" source="BingoTile_id" reference="BingoTiles">
                <SelectInput optionText="description" />
            </ReferenceInput>
            <ReferenceInput label="Team" source="BingoTeam_id" reference="BingoTeams">
                <SelectInput optionText="name" />
            </ReferenceInput>
            <NumberInput source="goal_count" />
            <NumberInput source="current_count" />
            <SelectInput
                source="status"
                choices={[
                    { id: 'not_started', name: 'Not Started' },
                    { id: 'in_progress', name: 'In Progress' },
                    { id: 'complete', name: 'Complete' },
                ]}
                required
            />
        </SimpleForm>
    </Create>
);

export const BingoProgressEdit = (props) => (
    <Edit {...props}>
        <SimpleForm>
            <ReferenceInput label="Tile" source="BingoTile_id" reference="BingoTiles">
                <SelectInput optionText="description" />
            </ReferenceInput>
            <ReferenceInput label="Team" source="BingoTeam_id" reference="BingoTeams">
                <SelectInput optionText="name" />
            </ReferenceInput>
            <NumberInput source="goal_count" />
            <NumberInput source="current_count" />
            <SelectInput
                source="status"
                choices={[
                    { id: 'not_started', name: 'Not Started' },
                    { id: 'in_progress', name: 'In Progress' },
                    { id: 'complete', name: 'Complete' },
                ]}
                required
            />
        </SimpleForm>
    </Edit>
);