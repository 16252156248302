import React from "react";
import Card from "@material-ui/core/Card";
import { makeStyles } from "@material-ui/core/styles";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CardMedia from "@material-ui/core/CardMedia";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { useTranslation } from 'react-i18next';
import ExpansionCard from "../../components/Shared/ExpansionCard"

export default function DropLog({ drops }) {
  const { t } = useTranslation();
  const noDropImage = "https://gyazo.com/2a08c96b2dda6880cf048e6105527da3";
  const datetime = (currentdate) =>
    `${currentdate.getDate()}/${currentdate.getMonth() + 1
    }/${currentdate.getFullYear()}`;
  const [validDrops, setValidDrops] = React.useState([]);
  const useStyles = makeStyles(() => ({
    media: {
      height: 65,
      backgroundSize: "contain",
      margin: "5px",
    },
  }));

  const classes = useStyles();

  React.useEffect(() => {
    if (drops) {
      const groups = drops
        .reduce((r, o) => {
          const y = o.date.split("-")[0];
          const key = y;
          r[key]
            ? r[key].data.push(o)
            : (r[key] = { group: `${y}`, data: [o] });
          return r;
        }, {});
      const result = Object.keys(groups)
        .map((k) => groups[k])
        .sort((a, b) => new Date(b.data[0].date) - new Date(a.data[0].date));
      setValidDrops(result);
    }
  }, [drops]);

  return (
    <ExpansionCard title={t('lookup.dropLog.title')}>
      <Grid container justify="center" spacing={3}>
        {validDrops.map((group) => (
          <Grid key={group.group} item xs={12} sm={12} md={12}>
            <ExpansionPanel>
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>{group.group}</Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <Grid container spacing={3}>
                  {group.data
                    .sort((a, b) => new Date(b.date) - new Date(a.date))
                    .map((drop) => (
                      <Grid key={drop.id} item xs={6} sm={4} md={2}>
                        <a
                          href={drop.screenshot || noDropImage}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <Card>
                            <CardMedia
                              className={classes.media}
                              image={drop.items.artwork}
                              title={drop.items.name}
                            />

                            <Grid container spacing={1}>
                              <Grid container item xs={12} justify="center">
                                <Typography>
                                  {datetime(new Date(drop.date))}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Card>
                        </a>
                      </Grid>
                    ))}
                </Grid>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </Grid>
        ))}
      </Grid>
    </ExpansionCard>
  );
}
