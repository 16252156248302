import React from 'react';
import {
  Filter,
  Pagination,
  List,
  Show,
  Create,
  Datagrid,
  TextField,
  SimpleForm,
  ReferenceField,
  ReferenceInput,
  AutocompleteInput,
  Edit,
  DateField,
  DateInput,
  BooleanInput,
  BooleanField,
  SimpleShowLayout,
  TopToolbar,
  EditButton,
  NumberInput,
} from 'react-admin';
import StudentTasks from '../StudentTasks';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';

const BigPagination = (props) => (
  <Pagination rowsPerPageOptions={[25, 50, 100, 2000]} {...props} />
);
const SearchFilter = (props) => (
  <Filter {...props}>
    <ReferenceInput
      label="Mentor Program"
      source="MentorProgram_id"
      reference="MentorPrograms"
      filter={{ id: props.mentorProgramIds }}
      alwaysOn
    >
      <AutocompleteInput optionText="name" />
    </ReferenceInput>
    <ReferenceInput
      label="Student"
      source="Member_id"
      reference="members"
      filter={{ Clan_id: props.clan.id }}
      alwaysOn
    >
      <AutocompleteInput optionText="username" />
    </ReferenceInput>
  </Filter>
);
export const StudentList = (props) => {
  return (
    <List
      {...props}
      pagination={<BigPagination />}
      perPage={25}
      filters={
        <SearchFilter {...props} mentorProgramIds={props.mentorProgramIds} />
      }
      filter={{ MentorProgram_id: props.mentorProgramIds, Clan_id: props.clan.id }}
    >
      <Datagrid rowClick="show">
        <ReferenceField label="Student" source="Member_id" reference="members">
          <TextField source="username" />
        </ReferenceField>
        <ReferenceField
          label="Mentor Program"
          source="MentorProgram_id"
          reference="MentorPrograms"
        >
          <TextField source="name" />
        </ReferenceField>
        <DateField source="joinDate" />
        <BooleanField source="active" />
        <BooleanField source="graduated" />
      </Datagrid>
    </List>
  );
};
const Aside = ({ record }) => (
  <div style={{ marginLeft: '24px', width: '80%' }}>
    <div style={{ marginBottom: '24px' }}>
      <StudentTasks record={record} />
    </div>
  </div>
);
const AddTaskButton = ({ record }) => (
  <Button
    component={Link}
    to={{
      pathname: '/TaskCompleted/create',
      state: {
        record: {
          approved: false,
          Member_id: record && record.Member_id,
        },
      },
    }}
  >
    Add completed task
  </Button>
);
const ShowActions = ({ basePath, data }) => (
  <TopToolbar>
    <AddTaskButton record={data} />
    <EditButton basePath={basePath} record={data} />
  </TopToolbar>
);
export const StudentShow = (props) => (
  <Show {...props} actions={<ShowActions />} aside={<Aside {...props} />}>
    <SimpleShowLayout>
      <ReferenceField label="Mentor" source="Member_id" reference="members">
        <TextField source="username" />
      </ReferenceField>
      <ReferenceField
        label="Mentor Program"
        source="MentorProgram_id"
        reference="MentorPrograms"
      >
        <TextField source="name" />
      </ReferenceField>
      <DateField source="joinDate" />
      <BooleanField source="active" />
      <BooleanField source="graduated" />
    </SimpleShowLayout>
  </Show>
);

export const StudentEdit = (props) => (
  <Edit {...props} undoable={false}>
    <SimpleForm>
      <ReferenceInput
        label="Mentor Program"
        source="MentorProgram_id"
        reference="MentorPrograms"
        filter={{ Clan_id: props.clan.id }}
        alwaysOn
      >
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
      <ReferenceInput
        label="Mentor"
        source="Member_id"
        reference="members"
        filter={{ Clan_id: props.clan.id }}
        alwaysOn
      >
        <AutocompleteInput optionText="username" />
      </ReferenceInput>
      <DateInput source="joinDate" />
      <BooleanInput source="active" />
      <BooleanInput source="graduated" />
    </SimpleForm>
  </Edit>
);

export const StudentCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <ReferenceInput
        label="Mentor Program"
        source="MentorProgram_id"
        reference="MentorPrograms"
        filter={{ Clan_id: props.clan.id }}
        alwaysOn
      >
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
      <ReferenceInput
        label="Student"
        source="Member_id"
        reference="members"
        filter={{ Clan_id: props.clan.id }}
        alwaysOn
      >
        <AutocompleteInput optionText="username" />
      </ReferenceInput>
      <DateInput source="joinDate" />
      <BooleanInput source="active" />
      <BooleanInput source="graduated" />
      <NumberInput source="Clan_id" initialValue={props.clan.id} disabled />
    </SimpleForm>
  </Create>
);
