import React, { useEffect, useContext, useState } from "react";
import { Card, CardContent } from "@mui/material";
import { PieChart } from "react-chartkick";
import LoadingCard from "../Loading/LoadingCard";
import { SubmissionContext } from "../../contexts/submissionContext";

export default function PointWheel({ record }) {
  const { fetchMember } = useContext(SubmissionContext);
  const [member, setMember] = useState(undefined);

  useEffect(() => {
    const loadMember = async () => setMember(await fetchMember(record.username));
    if (member === undefined && record && record.username) {
      loadMember(record.username);
    }
  }, [member, record, fetchMember]);

  return (
    <Card sx={{ display: "flex", alignItems: "center", justifyContent: "center", mb: 3 }}>
      <CardContent sx={{ padding: 1 }}>
        {member ? (
          <PieChart
            data={[
              ["PVM points", member.pvmPoints],
              ["Bump points", member.bumpPoints],
              ["Recruit points", member.recruitPoints],
              ["Event points", member.eventPoints],
              ["Mentor points", member.mentorPoints],
              ["Helper points", member.helperPoints],
              ["Alt points", member.altPoints],
              ["Donation points", member.donationPoints],
            ]}
            width="350px"
            height="350px"
          />
        ) : (
          <LoadingCard />
        )}
      </CardContent>
    </Card>
  );
}
