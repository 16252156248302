import React, { FunctionComponent } from "react";
import { CardContent } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { useTranslation } from "react-i18next";
import { Member } from "../../models";
import { Card } from "@mui/material";

interface StatusCardProps {
  member: Member;
}

const StatusCard: FunctionComponent<StatusCardProps> = ({ member }) => {
  const { t } = useTranslation();

  return (
    <Card>
      <CardContent>
        <Grid container>
          <Grid item xs={12}>
            <Typography>{t("lookup.statusCard.title")}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h5" component="h2">
              {member.active ? "Active" : "Inactive"}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default StatusCard;
