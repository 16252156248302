import React from "react";
import { Box } from "@mui/material";
import { PieChart } from "react-chartkick";

export default function StaffGraph({ data }) {
  return (
    <Box
      sx={{
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <PieChart data={data} width="350px" height="350px" />
    </Box>
  );
}
