import React, { useContext, useState } from "react";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { useTranslation } from 'react-i18next';
import { UserContext } from "../../contexts/userContext";
import { SubmissionContext } from "../../contexts/submissionContext";
import TextField from "@material-ui/core/TextField";

const Suggestions = () => {
  const { createSuggestion } = useContext(SubmissionContext);
  const { user } = useContext(UserContext);
  const { t } = useTranslation();
  const [suggestion, setSuggestion] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [link, setLink] = useState("");

  const submitSuggestion = async (
    e
  ) => {
    await createSuggestion({
      link: link,
      suggestion: suggestion,
      Member_id: user.id,
      Clan_id: user.Clan_id,
    })
    setSubmitted(true)
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography>
          {t("settings.suggestions.title")}
        </Typography>
      </Grid>
      {submitted ? (<>
        <Grid item xs={12}>
          <Typography>
            {t("settings.suggestions.submitted")}
          </Typography>
        </Grid>
      </>) : (
          <>
            <Grid item xs={12} sm={12} md={12}>
              <TextField
                id="suggestion"
                label={t("settings.suggestions.suggestionLabel")}
                fullWidth
                multiline
                value={suggestion}
                onChange={(e) => setSuggestion(e.target.value)}
                type="text"
                helperText={t("settings.suggestions.suggestionHelper")}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <TextField
                id="link"
                label={t("settings.suggestions.linkLabel")}
                value={link}
                fullWidth
                onChange={(e) => setLink(e.target.value)}
                type="text"
                helperText={t("settings.suggestions.linkHelper")}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <Button
                variant="outlined"
                onClick={submitSuggestion}
                disabled={suggestion === ""}
              >
                {t("settings.suggestions.submit")}
              </Button>
            </Grid>
          </>
        )}
    </Grid>
  )
};

export default Suggestions;
