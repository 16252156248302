import React from "react";
import {
  Filter,
  Pagination,
  List,
  Show,
  Create,
  Datagrid,
  TextField,
  TextInput,
  Edit,
  SimpleForm,
  NumberInput,
} from "react-admin";

const BigPagination = (props) => (
  <Pagination rowsPerPageOptions={[25, 50, 100, 2000]} {...props} />
);
const SearchFilter = (props) => (
  <Filter {...props}>
    <TextInput source="name" alwaysOn />
  </Filter>
);
export const PointCategoryList = (props) => (
  <List
    {...props}
    pagination={<BigPagination />}
    perPage={25}
    filters={<SearchFilter {...props} />}
    filter={{ Clan_id: props.clan.id }}
  >
    <Datagrid rowClick="show">
      <TextField source="name" />
    </Datagrid>
  </List>
);

export const PointCategoryShow = (props) => (
  <Show {...props}>
    <SimpleForm>
      <TextField source="name" />
      <TextField source="color" />
    </SimpleForm>
  </Show>
);

export const PointCategoryEdit = (props) => (
  <Edit {...props} undoable={false}>
    <SimpleForm>
      <TextInput source="name" />
      <TextInput source="color" />
    </SimpleForm>
  </Edit>
);

export const PointCategoryCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="name" />
      <TextInput source="color" />
      <NumberInput source="Clan_id" initialValue={props.clan.id} disabled />
    </SimpleForm>
  </Create>
);
