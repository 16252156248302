import React, { Fragment, useContext, useState } from "react";
import {
  Pagination,
  Filter,
  DateInput,
  SingleFieldList,
  TabbedShowLayout,
  Tab,
  TabbedForm,
  AutocompleteArrayInput,
  FormTab,
  ReferenceArrayInput,
  ReferenceArrayField,
  List,
  Show,
  Create,
  Datagrid,
  DateField,
  DateTimeInput,
  NumberField,
  TextField,
  NumberInput,
  TextInput,
  ReferenceField,
  ReferenceInput,
  AutocompleteInput,
  Edit,
  SimpleForm,
  useRefresh,
  useNotify,
  Button,
  Confirm,
  TopToolbar,
  EditButton,
} from "react-admin";
import { SubmissionContext } from "../../../contexts/submissionContext";

const PromoteHere = ({ record }) => {
  const [open, setOpen] = useState(false);
  const { promoteHere } = useContext(SubmissionContext)
  const refresh = useRefresh();
  const notify = useNotify();

  const handleClick = () => setOpen(true);
  const handleDialogClose = () => setOpen(false);
  const promote = () => {
    promoteHere(record.id)
      .then(() => {
        refresh();
        notify("Event promoted!");
      })
      .catch(() => notify("Error: Event not promoted", "warning"));
  };

  const handleConfirm = () => {
    promote();
    setOpen(false);
  };

  return (
    <>
      <Button label="Promote using @here" onClick={handleClick} />
      <Confirm
        isOpen={open}
        title="Promote Event"
        content="This will send an @here message to the #events channel in Discord about this event."
        onConfirm={handleConfirm}
        onClose={handleDialogClose}
      />
    </>
  );
};

const PromoteEveryone = ({ record }) => {
  const [open, setOpen] = useState(false);
  const { promoteEveryone } = useContext(SubmissionContext)
  const refresh = useRefresh();
  const notify = useNotify();

  const handleClick = () => setOpen(true);
  const handleDialogClose = () => setOpen(false);
  const promote = () => {
    promoteEveryone(record.id)
      .then(() => {
        refresh();
        notify("Event promoted!");
      })
      .catch(() => notify("Error: Event not promoted", "warning"));
  };

  const handleConfirm = () => {
    promote();
    setOpen(false);
  };

  return (
    <>
      <Button label="Promote using @everyone" onClick={handleClick} />
      <Confirm
        isOpen={open}
        title="Promote Event"
        content="This will send an @everyone message to the #events channel in Discord about this event."
        onConfirm={handleConfirm}
        onClose={handleDialogClose}
      />
    </>
  );
};

const PromoteNone = ({ record }) => {
  const [open, setOpen] = useState(false);
  const { promoteNone } = useContext(SubmissionContext)
  const refresh = useRefresh();
  const notify = useNotify();

  const handleClick = () => setOpen(true);
  const handleDialogClose = () => setOpen(false);
  const promote = () => {
    promoteNone(record.id)
      .then(() => {
        refresh();
        notify("Event promoted!");
      })
      .catch(() => notify("Error: Event not promoted", "warning"));
  };

  const handleConfirm = () => {
    promote();
    setOpen(false);
  };

  return (
    <>
      <Button label="Repost Event" onClick={handleClick} />
      <Confirm
        isOpen={open}
        title="Repost Event"
        content="This will send a message to the #events channel in Discord about this event with no @s."
        onConfirm={handleConfirm}
        onClose={handleDialogClose}
      />
    </>
  );
};

const PostDraft = ({ record }) => {
  const [open, setOpen] = useState(false);
  const { postDraft } = useContext(SubmissionContext)
  const refresh = useRefresh();
  const notify = useNotify();

  const handleClick = () => setOpen(true);
  const handleDialogClose = () => setOpen(false);
  const promote = () => {
    postDraft(record.id)
      .then(() => {
        refresh();
        notify("Draft Posted!");
      })
      .catch(() => notify("Error: Draft Not Posted", "warning"));
  };

  const handleConfirm = () => {
    promote();
    setOpen(false);
  };

  return (
    <>
      <Button label="Post Draft" onClick={handleClick} />
      <Confirm
        isOpen={open}
        title="Post Draft"
        content="This will send a message to the #events-drafts channel in staff section"
        onConfirm={handleConfirm}
        onClose={handleDialogClose}
      />
    </>
  );
};

const ShowActions = ({ basePath, data }) => (
  <TopToolbar>
    <EditButton basePath={basePath} record={data} />
    {console.log(new Date(data.date) > new Date())}
    {/* {data && new Date(data.date) > new Date() && ( */}
    <PromoteEveryone basePath={basePath} record={data} />
    {/* )} */}
    {data && new Date(data.date) > new Date() && (
      <PromoteHere basePath={basePath} record={data} />
    )}
    {data && new Date(data.date) > new Date() && (
      <PromoteNone basePath={basePath} record={data} />
    )}
    {data && new Date(data.date) > new Date() && (
      <PostDraft basePath={basePath} record={data} />
    )}
  </TopToolbar>
);

const BigPagination = (props) => (
  <Pagination rowsPerPageOptions={[25, 50, 100, 2000]} {...props} />
);
const SearchFilter = (props) => (
  <Filter {...props}>
    <TextInput source="name" alwaysOn />
    <ReferenceInput
      label="Host"
      source="Member_id"
      reference="members"
      filter={{ Clan_id: props.clan.id }}
      alwaysOn
    >
      <AutocompleteInput optionText="username" />
    </ReferenceInput>
    <DateInput source="date" alwaysOn />
    <ReferenceInput label="Admin" source="admin" reference="members" alwaysOn filter={{ Clan_id: props.clan.id }}>
      <AutocompleteInput optionText="username" />
    </ReferenceInput>
  </Filter>
);

export const EventList = (props) => (
  <List
    {...props}
    pagination={<BigPagination />}
    perPage={25}
    sort={{ field: "date", order: "DESC" }}
    filters={<SearchFilter {...props} />}
    filter={{ Clan_id: props.clan.id }}
  >
    <Datagrid rowClick="show">
      <TextField source="name" />
      <DateField source="date" />
      <TextField source="description" />
      <NumberField source="points" />
      <ReferenceField label="Host" source="Member_id" reference="members">
        <TextField source="username" />
      </ReferenceField>
      <ReferenceField label="Admin" source="admin" reference="members">
        <TextField source="username" />
      </ReferenceField>
    </Datagrid>
  </List>
);

export const EventShow = (props) => (
  <Show {...props} actions={<ShowActions />}>
    <TabbedShowLayout>
      <Tab label="event">
        <TextField source="name" />
        <DateField source="date" />
        <TextField source="description" />
        <TextField source="screenshot" />
        <NumberField source="points" />
        <ReferenceField label="Host" source="Member_id" reference="members">
          <TextField source="username" />
        </ReferenceField>
        <ReferenceField label="Admin" source="admin" reference="members">
          <TextField source="username" />
        </ReferenceField>
      </Tab>
      <Tab label="attendance" path="attendance">
        <ReferenceArrayField source="attendees" reference="members">
          <SingleFieldList>
            <TextField source="username" />
          </SingleFieldList>
        </ReferenceArrayField>
      </Tab>
    </TabbedShowLayout>
  </Show>
);

export const EventEdit = (props) => (
  <Edit {...props} undoable={false}>
    <TabbedForm>
      <FormTab label="event">
        <TextInput source="name" />
        <DateTimeInput source="date" />
        <TextInput source="description" multiline />
        <TextInput source="screenshot" />
        <NumberInput source="points" />
        <ReferenceInput label="Host" source="Member_id" reference="members" filter={{ Clan_id: props.clan.id }}>
          <AutocompleteInput optionText="username" />
        </ReferenceInput>
      </FormTab>
      <FormTab label="attendance" path="attendance">
        <ReferenceArrayInput source="attendees" reference="members" filter={{ Clan_id: props.clan.id }}>
          <AutocompleteArrayInput optionText="username" />
        </ReferenceArrayInput>
      </FormTab>
    </TabbedForm>
  </Edit>
);

export const EventCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="name" />
      <DateTimeInput source="date" />
      <TextInput source="description" multiline />
      <TextInput source="screenshot" />
      <NumberInput source="points" />
      <ReferenceInput label="Host" source="Member_id" reference="members" filter={{ Clan_id: props.clan.id }}>
        <AutocompleteInput optionText="username" />
      </ReferenceInput>
      <NumberInput source="Clan_id" initialValue={props.clan.id} disabled />
    </SimpleForm>
  </Create>
);
