import React, { useContext } from "react";
import { Route, Switch } from "react-router-dom";
import { UserContext } from "./contexts/userContext";
import Header from "./components/Header/Header";
import StandaloneRoutes from "./standaloneRoutes";
import LoadingOverlay from "./components/Loading/LoadingOverlay";
import { Box } from "@mui/material";

export default function Routes() {
  const { user, userFetched, noClan } = useContext(UserContext);

  return (
    <Box
      sx={{
        display: { xs: "block", sm: "flex" }, // Media query for small screens
      }}
    >
      <Header />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          backgroundColor: "background.default", // Accessing the theme's background color
          marginTop: 5, // Theme spacing (50px)
          minHeight: "100vh",
        }}
      >
        {(user.id || userFetched || noClan) && (
          <Route
            render={({ location }) => (
              <Switch location={location}>
                <StandaloneRoutes />
              </Switch>
            )}
          />
        )}
        <LoadingOverlay />
      </Box>
    </Box>
  );
}
