import React from "react";
import { ThemeContextProvider } from "./contexts/themeContext";
import { UserContextProvider } from "./contexts/userContext";
import { ClanContextProvider } from "./contexts/clanContext";
import { SubmissionContextProvider } from "./contexts/submissionContext";
import { ChecklistContextProvider } from "./contexts/checklistContext";

interface ContextsProps {
  children: JSX.Element;
}

const Contexts = (props: ContextsProps): JSX.Element => {
  return (
    <ThemeContextProvider>
      <ClanContextProvider>
        <SubmissionContextProvider >
          <UserContextProvider>
            <ChecklistContextProvider>
              {props.children}
            </ChecklistContextProvider>
          </UserContextProvider>
        </SubmissionContextProvider>
      </ClanContextProvider>
    </ThemeContextProvider>
  );
}

export default Contexts