import React from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "./Toolbar";
import Paper from "@material-ui/core/Paper";
import Drawer from "./Drawer";

export default function Header() {
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  return (
    <>
      <AppBar>
        <Paper square variant='outlined'>
          <Toolbar handleDrawerToggle={handleDrawerToggle} />
        </Paper>
      </AppBar>
      <Drawer handleDrawerToggle={handleDrawerToggle} open={mobileOpen} />
    </>
  );
}
