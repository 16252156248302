import React, { useState, useContext } from "react";
import { PieChart, Pie, Sector, Cell } from "recharts";
import COLORS from "./Colors";
import { ThemeContext } from "../../contexts/themeContext";
import "chart.js";

const Boss = ({ killcounts, ehb }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const { mode } = useContext(ThemeContext);

  const onPieEnter = (data, index) => {
    setActiveIndex(index);
  };

  const renderActiveShape = (props) => {
    const RADIAN = Math.PI / 180;
    const {
      cx,
      cy,
      midAngle,
      innerRadius,
      outerRadius,
      startAngle,
      endAngle,
      fill,
      payload,
      percent,
      value,
      ehb,
    } = props;

    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? "start" : "end";

    return (
      <g>
        <text x={cx} y={cy} dy={8} textAnchor="middle" fill={mode === "dark" ? "white" : null}>
          {payload.name}
        </text>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
        <Sector
          cx={cx}
          cy={cy}
          startAngle={startAngle}
          endAngle={endAngle}
          innerRadius={outerRadius + 6}
          outerRadius={outerRadius + 10}
          fill={fill}
        />
        <path
          d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
          stroke={fill}
          fill="none"
        />
        <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
        <text
          x={ex + (cos >= 0 ? 1 : -1) * 12}
          y={ey}
          dy={0}
          textAnchor={textAnchor}
          fill={mode === "dark" ? "white" : null}
        >
          {`${ehb ? "Hours" : "Kills"}: ${value.toFixed(2)}`}
        </text>
        <text
          x={ex + (cos >= 0 ? 1 : -1) * 12}
          y={ey}
          dy={18}
          textAnchor={textAnchor}
          fill={mode === "dark" ? "white" : null}
        >
          {`(Rate ${(percent * 100).toFixed(2)}%)`}
        </text>
      </g>
    );
  };

  const data = killcounts
    .map((killcount) => ({
      name: killcount.boss.name,
      value: ehb
        ? killcount.boss.ehb > 0
          ? killcount.value / killcount.boss.ehb
          : 0
        : killcount.value,
      ehb,
    }))
    .sort((a, b) =>
      a.value > b.value
        ? 1
        : a.value === b.value
          ? a.size > b.size
            ? 1
            : -1
          : -1
    )
    .reverse();

  return (
    <PieChart width={650} height={600} style={{ margin: "auto" }}>
      <Pie
        activeIndex={activeIndex}
        activeShape={(props) => renderActiveShape(props)}
        data={data}
        cx={300}
        cy={300}
        innerRadius={100}
        outerRadius={150}
        fill="#000"
        dataKey="value"
        onMouseEnter={onPieEnter}
      >
        {data.map((entry, index) => (
          <Cell
            key={`cell-${index}`}
            fill={COLORS[index % COLORS.length]}
          />
        ))}
      </Pie>
    </PieChart>
  );
};

export default Boss;
