import React from "react";
import {
  UrlField,
  Pagination,
  Filter,
  SingleFieldList,
  TabbedShowLayout,
  Tab,
  TabbedForm,
  AutocompleteArrayInput,
  FormTab,
  ReferenceArrayInput,
  ReferenceArrayField,
  List,
  Show,
  Create,
  Datagrid,
  BooleanField,
  NumberField,
  TextField,
  NumberInput,
  TextInput,
  BooleanInput,
  AutocompleteInput,
  Edit,
} from "react-admin";

const BigPagination = (props) => (
  <Pagination rowsPerPageOptions={[25, 50, 100, 2000]} {...props} />
);
const SearchFilter = (props) => (
  <Filter {...props}>
    <TextInput source='name' alwaysOn />
    <NumberField source='order' />
  </Filter>
);

export const RankList = (props) => (
  <List
    {...props}
    pagination={<BigPagination />}
    perPage={25}
    sort={{ field: "order", order: "ASC" }}
    filters={<SearchFilter {...props} />}
    filter={{ Clan_id: props.clan.id }}
  >
    <Datagrid rowClick='show'>
      <TextField source='name' />
      <NumberField source='order' />
      <UrlField source='artwork' />
      <NumberField source='value' />
    </Datagrid>
  </List>
);

export const RankShow = (props) => (
  <Show {...props}>
    <TabbedShowLayout>
      <Tab label='rank'>
        <TextField source='name' />
        <NumberField source='order' />
        <TextField source='artwork' />
        <NumberField source='value' />
        <NumberField source='pvm' />
        <NumberField source='community' />
        <NumberField source='total' />
        <NumberField source='totalLevel' />
        <NumberField source='magic' />
        <NumberField source='range' />
        <NumberField source='ehb' />
        <NumberField source='coins' />
        <NumberField source='combat' />
        <NumberField source='herblore' />
        <NumberField source='raids' />
        <NumberField source='months' />
        <BooleanField source='pool' />
        <BooleanField source='box' />
        <BooleanField source='augury' />
        <BooleanField source='rigour' />
        <BooleanField source='piety' />
        <BooleanField source='combatAchievementsElite' />
        <BooleanField source='combatAchievementsMaster' />
        <BooleanField source='combatAchievementsGrandmaster' />
        <NumberField source='tasks' />
        <TextField source='discordRoleId' />
        <TextField source='description' />
      </Tab>
      <Tab label='items' path='items'>
        <ReferenceArrayField source='items' reference='items'>
          <SingleFieldList>
            <TextField source='name' />
          </SingleFieldList>
        </ReferenceArrayField>
      </Tab>
      <Tab label='members' path='members'>
        <ReferenceArrayField source='members' reference='members'>
          <Datagrid>
            <TextField source='username' />
          </Datagrid>
        </ReferenceArrayField>
      </Tab>
    </TabbedShowLayout>
  </Show>
);

export const RankEdit = (props) => (
  <Edit {...props} undoable={false}>
    <TabbedForm>
      <FormTab label='rank'>
        <TextInput source='name' />
        <NumberInput source='order' />
        <TextInput source='artwork' />
        <NumberInput source='value' />
        <NumberInput source='pvm' />
        <NumberInput source='community' />
        <NumberInput source='total' />
        <NumberInput source='totalLevel' />
        <NumberInput source='magic' />
        <NumberInput source='range' />
        <NumberInput source='ehb' />
        <NumberInput source='coins' />
        <NumberInput source='combat' />
        <NumberInput source='herblore' />
        <NumberInput source='raids' />
        <NumberInput source='months' />
        <BooleanInput source='pool' />
        <BooleanInput source='box' />
        <BooleanInput source='augury' />
        <BooleanInput source='rigour' />
        <BooleanInput source='piety' />
        <BooleanInput source='combatAchievementsElite' />
        <BooleanInput source='combatAchievementsMaster' />
        <BooleanInput source='combatAchievementsGrandmaster' />
        <NumberInput source='tasks' />
        <TextInput source='discordRoleId' />
        <TextInput multiline source='description' />
      </FormTab>
      <FormTab label='items' path='items'>
        <ReferenceArrayInput
          source='items'
          reference='items'
          allowEmpty
          filter={{ Clan_id: props.clan.id }}
        >
          <AutocompleteArrayInput
            optionText='name'
            allowEmpty
            emptyText='-- none --'
          />
        </ReferenceArrayInput>
      </FormTab>
    </TabbedForm>
  </Edit>
);

export const RankCreate = (props) => (
  <Create {...props}>
    <TabbedForm>
      <FormTab label='rank'>
        <TextInput source='name' />
        <NumberInput source='order' />
        <TextInput source='artwork' />
        <NumberInput source='value' />
        <NumberInput source='pvm' />
        <NumberInput source='community' />
        <NumberInput source='total' />
        <NumberInput source='totalLevel' />
        <NumberInput source='magic' />
        <NumberInput source='range' />
        <NumberInput source='ehb' />
        <NumberInput source='coins' />
        <NumberInput source='combat' />
        <NumberInput source='herblore' />
        <NumberInput source='raids' />
        <NumberInput source='months' />
        <BooleanInput source='pool' />
        <BooleanInput source='box' />
        <BooleanInput source='augury' />
        <BooleanInput source='rigour' />
        <BooleanInput source='piety' />
        <NumberInput source='tasks' />
        <TextInput source='discordRoleId' />
        <TextInput multiline source='description' />
        <NumberInput source='Clan_id' initialValue={props.clan.id} disabled />
      </FormTab>
      <FormTab label='items' path='items'>
        <ReferenceArrayInput
          source='items'
          reference='items'
          allowEmpty
          filter={{ Clan_id: props.clan.id }}
        >
          <AutocompleteInput
            optionText='name'
            allowEmpty
            emptyText='-- none --'
          />
        </ReferenceArrayInput>
      </FormTab>
    </TabbedForm>
  </Create>
);
