import React from "react";
import {
  List,
  Show,
  Pagination,
  Create,
  SimpleShowLayout,
  Datagrid,
  NumberField,
  TextField,
  NumberInput,
  TextInput,
  Edit,
  SimpleForm,
} from "react-admin";

const BigPagination = (props) => (
  <Pagination rowsPerPageOptions={[25, 50, 100, 2000]} {...props} />
);

export const ScrapeList = (props) => (
  <List {...props} pagination={<BigPagination />} perPage={25} filter={{ Clan_id: props.clan.id }}>
    <Datagrid rowClick="show">
      <NumberField source="page" />
      <NumberField source="post" />
      <TextField source="url" />
    </Datagrid>
  </List>
);

export const ScrapeShow = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <NumberField source="page" />
      <NumberField source="post" />
      <TextField source="url" />
    </SimpleShowLayout>
  </Show>
);

export const ScrapeEdit = (props) => (
  <Edit {...props} undoable={false}>
    <SimpleForm>
      <NumberInput source="page" />
      <NumberInput source="post" />
      <TextInput source="url" />
    </SimpleForm>
  </Edit>
);

export const ScrapeCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <NumberInput source="page" />
      <NumberInput source="post" />
      <TextInput source="url" />
      <NumberInput source="Clan_id" initialValue={props.clan.id} disabled />
    </SimpleForm>
  </Create>
);
