import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    ReferenceField,
    Pagination,
    TextInput,
    ReferenceInput,
    SelectInput,
    Filter,
    Show,
    TabbedShowLayout,
    Tab,
    Create,
    SimpleForm,
    Edit
} from 'react-admin';


const BingoTileFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Team Name" source="name" alwaysOn />
        <ReferenceInput label="Board" source="BingoBoard_id" reference="BingoBoards" alwaysOn>
            <SelectInput optionText="name" />
        </ReferenceInput>
    </Filter>
);

const BigPagination = (props) => (
    <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />
);

export const BingoTileList = (props) => (
    <List
        {...props}
        filters={<BingoTileFilter />}
        pagination={<BigPagination />}
        perPage={25}
    >
        <Datagrid rowClick="show">
            <ReferenceField label="Board" source="BingoBoard_id" reference="BingoBoards">
                <TextField source="name" />
            </ReferenceField>
            <TextField source="description" />
            <TextField source="row" />
            <TextField source="col" />
            <TextField source="goal_count" />
        </Datagrid>
    </List>
);

export const BingoTileShow = (props) => (
    <Show {...props}>
        <TabbedShowLayout>
            <Tab label="Details">
                <ReferenceField label="Board" source="BingoBoard_id" reference="BingoBoards">
                    <TextField source="name" />
                </ReferenceField>
                <TextField source="description" />
                <TextField source="row" />
                <TextField source="col" />
                <TextField source="goal_count" />
                <TextField source="background" />
            </Tab>
        </TabbedShowLayout>
    </Show>
);

export const BingoTileCreate = (props) => (
    <Create {...props}>
        <SimpleForm>
            <ReferenceInput label="Board" source="BingoBoard_id" reference="BingoBoards">
                <SelectInput optionText="name" />
            </ReferenceInput>
            <TextInput source="description" />
            <TextInput source="row" />
            <TextInput source="col" />
            <TextInput source="goal_count" />
            <TextInput source="background" />
        </SimpleForm>
    </Create>
);

export const BingoTileEdit = (props) => (
    <Edit {...props}>
        <SimpleForm>
            <ReferenceInput label="Board" source="BingoBoard_id" reference="BingoBoards">
                <SelectInput optionText="name" />
            </ReferenceInput>
            <TextInput source="description" />
            <TextInput source="row" />
            <TextInput source="col" />
            <TextInput source="goal_count" />
            <TextInput source="background" />
        </SimpleForm>
    </Edit>
);