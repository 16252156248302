import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import Grid from "@material-ui/core/Grid";
import { Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";

export default function WorkSection(props) {
  const { rank } = props;
  const useStyles = makeStyles(() => ({
    media: {
      height: 65,
      backgroundSize: "contain",
      margin: "5px",
    },
  }));

  const classes = useStyles();
  const { t } = useTranslation();

  const checkbox = (label, icon = <Check />) => (
    <Grid item xs={12} md={12}>
      <FormControlLabel
        control={<Checkbox checkedIcon={icon} icon={icon} checked />}
        label={label}
      />
    </Grid>
  );
  return (
    <Grid container>
      <Grid item xs={12} md={3}>
        <Grid container spacing={3}>
          {(rank.total || rank.pvm || rank.community || rank.ehb) && (
            <Grid item xs={12} md={12}>
              <Grid container>
                <Grid item xs={12}>
                  <Typography variant='h5'>
                    {t("requirements.rank.points")}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={12}>
                  {rank.total &&
                    checkbox(
                      `${rank.total} Total`,
                      <img
                        src='https://oldschool.runescape.wiki/images/1/1b/Achievement_Diaries_icon.png?b4e0c'
                        alt='total points'
                      />
                    )}
                  {rank.pvm &&
                    checkbox(
                      `${rank.pvm} PvM`,
                      <img
                        src='https://oldschool.runescape.wiki/images/5/5c/Minigame_icon.png?e4ffe'
                        alt='pvm points'
                      />
                    )}
                  {rank.community &&
                    checkbox(
                      `${rank.community} Community`,
                      <img
                        src='https://oldschool.runescape.wiki/images/2/2b/Quest_point_icon.png?dc356'
                        alt='community points'
                      />
                    )}
                  {rank.ehb &&
                    checkbox(
                      `${rank.ehb} EHB`,
                      <img
                        src='https://oldschool.runescape.wiki/images/a/af/Kourend_Favour_icon.png?6b650'
                        alt='total ehb'
                      />
                    )}
                </Grid>
              </Grid>
            </Grid>
          )}
          <Grid item xs={12} md={12}>
            <Grid container>
              <Grid item xs={12}>
                <Typography variant='h5'>
                  {t("requirements.rank.skills")}
                </Typography>
              </Grid>
              <Grid item xs={12} md={12}>
                {rank.combat &&
                  checkbox(
                    `${rank.combat}${rank.combat === 125 ? "+" : ""} Combat`,
                    <img
                      src='https://vignette.wikia.nocookie.net/2007scape/images/8/8f/Combat_icon.png/revision/latest?cb=20180424020420'
                      alt='combat level'
                    />
                  )}
                {rank.totalLevel &&
                  checkbox(
                    `${rank.totalLevel}+ Total`,
                    <img
                      src='https://oldschool.runescape.wiki/images/b/bd/Stats_icon.png?1b467'
                      alt='total level'
                    />
                  )}
                {rank.range &&
                  checkbox(
                    `${rank.range} Ranged`,
                    <img
                      src='https://oldschool.runescape.wiki/images/1/19/Ranged_icon.png?01b0e'
                      alt='range level'
                    />
                  )}
                {rank.magic &&
                  checkbox(
                    `${rank.magic} Magic`,
                    <img
                      src='https://oldschool.runescape.wiki/images/5/5c/Magic_icon.png?334cf'
                      alt='magic level'
                    />
                  )}
                {rank.herblore &&
                  checkbox(
                    `${rank.herblore} Herblore`,
                    <img
                      src='https://oldschool.runescape.wiki/images/0/03/Herblore_icon.png?ffa9e'
                      alt='herblore level'
                    />
                  )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={12}>
            <Grid container>
              <Grid item xs={12}>
                <Typography variant='h5'>
                  {t("requirements.rank.misc")}
                </Typography>
              </Grid>
              <Grid item xs={12} md={12}>
                {rank.coins &&
                  checkbox(
                    `${rank.coins}m+ PvM Gear`,
                    <img
                      src='https://oldschool.runescape.wiki/images/3/30/Coins_10000.png?7b79a'
                      alt='total gear value'
                    />
                  )}
                {rank.pool &&
                  checkbox(
                    "Ornate Pool",
                    <img
                      src='https://oldschool.runescape.wiki/images/7/78/Ornate_rejuvenation_pool_icon.png?51916'
                      alt='ornate pool'
                    />
                  )}
                {rank.box &&
                  checkbox(
                    "Ornate Jewellery Box",
                    <img
                      src='https://oldschool.runescape.wiki/images/f/f7/Ornate_jewellery_box_icon.png?df68b'
                      alt='ornate jewellery box'
                    />
                  )}
                {rank.combatAchievementsElite &&
                  checkbox(
                    `Elite CA`,
                    <img
                      src='https://oldschool.runescape.wiki/images/Tztok_slayer_helmet.png?b3430'
                      alt='Elite_Combat_Achievement'
                    />
                  )}
                {rank.combatAchievementsMaster &&
                  checkbox(
                    `Master CA`,
                    <img
                      src='https://oldschool.runescape.wiki/images/Vampyric_slayer_helmet.png?fe40f'
                      alt='Master_Combat_Achievement'
                    />
                  )}{rank.combatAchievementsGrandmaster &&
                    checkbox(
                      `Grandmaster CA`,
                      <img
                        src='https://oldschool.runescape.wiki/images/archive/20210820150708%21Tzkal_slayer_helmet.png?b0e84'
                        alt='Grandmaster_Combat_Achievement'
                      />
                    )}
                {rank.raids &&
                  checkbox(
                    `${rank.raids} Combined raids KC`,
                    <img
                      src='https://oldschool.runescape.wiki/images/6/6a/Scythe_of_vitur.png?5672e'
                      alt='total raids kc'
                    />
                  )}
                {rank.piety &&
                  checkbox(
                    "Piety",
                    <img
                      src='https://oldschool.runescape.wiki/images/a/a2/Piety.png?58239'
                      alt='piety'
                    />
                  )}
                {rank.rigour &&
                  checkbox(
                    "Rigour",
                    <img
                      src='https://oldschool.runescape.wiki/images/5/5b/Rigour.png?159e1'
                      alt='rigour'
                    />
                  )}
                {rank.augury &&
                  checkbox(
                    "Augury",
                    <img
                      src='https://oldschool.runescape.wiki/images/9/93/Augury.png?f234e'
                      alt='augury'
                    />
                  )}
                {rank.tasks &&
                  checkbox(
                    `${rank.tasks} Tier 4 Diaries`,
                    <img
                      src='https://oldschool.runescape.wiki/images/6/6a/Scythe_of_vitur.png?5672e'
                      alt='total raids kc'
                    />
                  )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {rank.description && (
          <Grid item xs={12} md={12}>
            <Typography variant='body1' style={{ whiteSpace: "pre-line" }}>
              {rank.description}
            </Typography>
          </Grid>
        )}
      </Grid>
      <Grid item xs={12} md={9}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant='h5'>{t("requirements.rank.gear")}</Typography>
          </Grid>
          {rank.items &&
            rank.items
              .map((item) => (
                <Grid key={item.id} item xs={6} sm={4} md={3}>
                  <Card>
                    <CardMedia
                      className={classes.media}
                      image={item.artwork}
                      title={item.name}
                    />
                  </Card>
                </Grid>
              ))}
        </Grid>
      </Grid>
    </Grid>
  );
}
