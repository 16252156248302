import React from "react";
import { Card  } from "@mui/material";
import Typography from "@material-ui/core/Typography";
import LinearProgress from "@material-ui/core/LinearProgress";
import { withStyles } from "@material-ui/core/styles";
import { CardContent } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { useTranslation } from 'react-i18next';

export default function BanMeter({ member }) {
  const { t } = useTranslation();
  const { offenses } = member;
  const OneStrike = withStyles({
    root: {
      height: 20,
    },
    colorPrimary: {
      backgroundColor: "#fafafa",
    },
    barColorPrimary: {
      backgroundColor: "green",
    },
  })(LinearProgress);

  const TwoStrikes = withStyles({
    root: {
      height: 20,
    },
    colorPrimary: {
      backgroundColor: "#fafafa",
    },
    barColorPrimary: {
      backgroundColor: "#ffeb3b",
    },
  })(LinearProgress);

  const ThreeStrikes = withStyles({
    root: {
      height: 20,
    },
    colorPrimary: {
      backgroundColor: "#fafafa",
    },
    barColorPrimary: {
      backgroundColor: "#ffc107",
    },
  })(LinearProgress);

  const FourStrikes = withStyles({
    root: {
      height: 20,
    },
    colorPrimary: {
      backgroundColor: "#fafafa",
    },
    barColorPrimary: {
      backgroundColor: "#ff9800",
    },
  })(LinearProgress);

  const FiveStrikes = withStyles({
    root: {
      height: 20,
    },
    colorPrimary: {
      backgroundColor: "#fafafa",
    },
    barColorPrimary: {
      backgroundColor: "red",
    },
  })(LinearProgress);

  return (
    <Card>
      <CardContent>
          <Grid container>
            <Grid item xs={12}>
              <Typography>{t('lookup.banMeter.title')}</Typography>

            </Grid>
            <Grid item xs={12}>
              {offenses.length <= 1 ? (
                <OneStrike variant="determinate" value={offenses.length * 20} />
              ) : offenses.length === 2 ? (
                <TwoStrikes variant="determinate" value={40} />
              ) : offenses.length === 3 ? (
                <ThreeStrikes variant="determinate" value={60} />
              ) : offenses.length === 4 ? (
                <FourStrikes variant="determinate" value={80} />
              ) : (
                <FiveStrikes variant="determinate" value={100} />
              )}
            </Grid>
            <Grid item xs={12}>
              <Typography>
              {`${offenses.length}${t('lookup.banMeter.strikes')}${offenses.length === 1 ? "" : "s"}`}
            </Typography>
            </Grid>
          </Grid>
      </CardContent>
    </Card>
  );
}
