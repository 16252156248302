import React from "react";
import { Card, CardContent, Grid, Typography, Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";

const GreenCheck = styled(CheckCircleIcon)(({ theme }) => ({
  color: theme.palette.success.main,
}));
const RedCheck = styled(ErrorIcon)(({ theme }) => ({
  color: theme.palette.error.main,
}));

export default function BasicDetails({ record }) {
  return (
    <Card sx={{ display: "flex" }}>
      <CardContent sx={{ width: "100%" }}>
        {record?.id && (
          <Box>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={3}>
                    <Typography variant="body1">Active:</Typography>
                  </Grid>
                  <Grid item xs={3}>{record.active ? <GreenCheck /> : <RedCheck />}</Grid>
                  <Grid item xs={3}>
                    <Typography variant="body1">Gearcheck</Typography>
                  </Grid>
                  <Grid item xs={3}>{record.gearcheck ? <GreenCheck /> : <RedCheck />}</Grid>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={6}>
                    <Typography variant="body1">Join Date</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body1">
                      <b>
                        {new Date(record.joinDate)
                          .toISOString()
                          .slice(0, 10)
                          .replace(/-/g, "/")}
                      </b>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        )}
      </CardContent>
    </Card>
  );
}
