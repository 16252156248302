import React, { useContext, useState } from 'react';
import { SubmissionContext } from "../../../contexts/submissionContext";
import {
    List,
    Datagrid,
    TextField,
    NumberField,
    Tab,
    TextInput,
    Filter,
    Pagination,
    DateField,
    DateInput,
    Show,
    TabbedShowLayout,
    Create,
    SimpleForm,
    Edit,
    NumberInput,
    ReferenceArrayField,
    EditButton,
    TopToolbar,
    useRefresh,
    useNotify,
    Button,
    Confirm,
    UrlField,
} from 'react-admin';

const ShowActions = ({ basePath, data }) => (
    <TopToolbar>
        <UpdateImage basePath={basePath} record={data} />
        <UpdateTeamImage basePath={basePath} record={data} />
        <EditButton basePath={basePath} record={data} />
    </TopToolbar>
);


const UpdateImage = ({ record }) => {
    const [open, setOpen] = useState(false);
    const { updateBoardImage } = useContext(SubmissionContext)
    const refresh = useRefresh();
    const notify = useNotify();

    const handleClick = () => setOpen(true);
    const handleDialogClose = () => setOpen(false);
    const promote = () => {
        updateBoardImage(record.id)
            .then(() => {
                refresh();
                notify("Board updated! Please refresh the page!");
            })
            .catch(() => notify("Error: Bingo Board not updated", "warning"));
    };

    const handleConfirm = () => {
        promote();
        setOpen(false);
    };

    return (
        <>
            <Button label="Update Board Image" onClick={handleClick} />
            <Confirm
                isOpen={open}
                title="Update Board Image"
                content="This will generate a new Bingo Board based on the current score."
                onConfirm={handleConfirm}
                onClose={handleDialogClose}
            />
        </>
    );
};

const UpdateTeamImage = ({ record }) => {
    const [open, setOpen] = useState(false);
    const { updateTeamBoardImage } = useContext(SubmissionContext)
    const refresh = useRefresh();
    const notify = useNotify();

    const handleClick = () => setOpen(true);
    const handleDialogClose = () => setOpen(false);
    const promote = () => {
        updateTeamBoardImage(record.id)
            .then(() => {
                refresh();
                notify("Team boards updated! Please refresh the page!");
            })
            .catch(() => notify("Error: Team Bingo Boards not updated", "warning"));
    };

    const handleConfirm = () => {
        promote();
        setOpen(false);
    };

    return (
        <>
            <Button label="Update Team Board Images" onClick={handleClick} />
            <Confirm
                isOpen={open}
                title="Update Team Board Images"
                content="This will generate a new team Bingo Boards based on the current score."
                onConfirm={handleConfirm}
                onClose={handleDialogClose}
            />
        </>
    );
};


const BingoBoardFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Board Name" source="name" alwaysOn />
    </Filter>
);

const BigPagination = (props) => (
    <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />
);

export const BingoBoardList = (props) => {
    return (
        <List
            {...props}
            filters={<BingoBoardFilter />}
            pagination={<BigPagination />}
            perPage={25}
        >
            <Datagrid rowClick="show">
                <TextField source="name" />
                <NumberField source="rows" label="Rows" />
                <NumberField source="cols" label="Columns" />
                <DateField source="start_date" label="Start Date" />
                <DateField source="end_date" label="End Date" />
                <TextField source="wom_id" label="WiseOldMan ID" />
                <UrlField source="board_url" label="Board Url" />
            </Datagrid>
        </List>
    );
};


export const BingoBoardShow = (props) => (
    <Show {...props} actions={<ShowActions />}>
        <TabbedShowLayout>
            <Tab label="Details">
                <TextField source="name" />
                <NumberField source="rows" label="Rows" />
                <NumberField source="cols" label="Columns" />
                <DateField source="start_date" label="Start Date" />
                <DateField source="end_date" label="End Date" />
                <TextField source="wom_id" label="WiseOldMan ID" />
                <UrlField source="template" label="Template URL" />
                <UrlField source="board_url" label="Board Url" />
                <TextField source="tile_color" label="Tile Color" />
                <TextField source="text_color" label="Text Color" />

            </Tab>
            <Tab label="Teams">
                <ReferenceArrayField source="teams" reference="BingoTeams">
                    <Datagrid bulkActionButtons={false}>
                        <TextField source="name" />
                        <UrlField source="board_url" label="Board URL" />
                        <EditButton />
                    </Datagrid>
                </ReferenceArrayField>
            </Tab>
            <Tab label="Tiles">
                <ReferenceArrayField source="tiles" reference="BingoTiles">
                    <Datagrid bulkActionButtons={false}>
                        <TextField source="description" />
                        <TextField source="row" />
                        <TextField source="col" />
                        <TextField source="goal_count" />
                        <EditButton />
                    </Datagrid>
                </ReferenceArrayField>
            </Tab>
        </TabbedShowLayout>
    </Show>
);


export const BingoBoardCreate = (props) => (
    <Create {...props}>
        <SimpleForm>
            <TextInput label="Name" source="name" />
            <NumberInput label="Rows" source="rows" />
            <NumberInput label="Columns" source="cols" />
            <DateInput label="Start Date" source="start_date" />
            <DateInput label="End Date" source="end_date" />
            <TextInput label="WiseOldMan ID" source="wom_id" />
            <TextInput label="Template URL" source="template" />
            <TextInput label="Board URL" source="board_url" />
            <TextInput label="Tile Color" source="tile_color" />
            <TextInput label="Text Color" source="text_color" />
        </SimpleForm>
    </Create>
);

export const BingoBoardEdit = (props) => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput label="Name" source="name" />
            <NumberInput label="Rows" source="rows" />
            <NumberInput label="Columns" source="cols" />
            <DateInput label="Start Date" source="start_date" />
            <DateInput label="End Date" source="end_date" />
            <TextInput label="WiseOldMan ID" source="wom_id" />
            <TextInput label="Template URL" source="template" />
            <TextInput label="Board URL" source="board_url" />
            <TextInput label="Tile Color" source="tile_color" />
            <TextInput label="Text Color" source="text_color" />
        </SimpleForm>
    </Edit>
);