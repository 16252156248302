import React, { Fragment, useState } from "react";
import {
  List,
  Filter,
  TabbedShowLayout,
  Tab,
  Button,
  Confirm,
  useUpdateMany,
  useRefresh,
  useNotify,
  useUnselectAll,
  Pagination,
  BulkDeleteButton,
  Show,
  Create,
  Datagrid,
  BooleanField,
  TextField,
  TextInput,
  ReferenceField,
  BooleanInput,
  ReferenceInput,
  AutocompleteInput,
  Edit,
  SimpleForm,
  NumberInput,
} from "react-admin";

const BigPagination = (props) => (
  <Pagination rowsPerPageOptions={[25, 50, 100, 2000]} {...props} />
);
const SearchFilter = (props) => (
  <Filter {...props}>
    <TextInput source="response" alwaysOn />
    <ReferenceInput
      label="Member"
      source="Member_id"
      reference="members"
      filter={{ Clan_id: props.clan.id }}
      alwaysOn
    >
      <AutocompleteInput optionText="username" />
    </ReferenceInput>
    <ReferenceInput
      label="Question"
      source="Question_id"
      reference="Questions"
      filter={{ Clan_id: props.clan.id }}
      alwaysOn
    >
      <AutocompleteInput optionText="question" />
    </ReferenceInput>
  </Filter>
);

const ApproveResponses = ({ selectedIds, resource }) => {
  const [open, setOpen] = useState(false);
  const refresh = useRefresh();
  const notify = useNotify();
  const unselectAll = useUnselectAll();
  const [updateMany, { loading }] = useUpdateMany(
    "Responses",
    selectedIds,
    { approved: true },
    {
      onSuccess: () => {
        refresh();
        notify("Responses approved!");
        unselectAll(resource);
      },
      onFailure: () => notify("Error: Responses not approved", "warning"),
    }
  );
  const handleClick = () => setOpen(true);
  const handleDialogClose = () => setOpen(false);

  const handleConfirm = () => {
    updateMany();
    setOpen(false);
  };
  return (
    <>
      <Button label="Approve Responses" onClick={handleClick} />
      <Confirm
        isOpen={open}
        loading={loading}
        title="Approve Responses"
        content="Are you sure you want to approve these responses?"
        onConfirm={handleConfirm}
        onClose={handleDialogClose}
      />
    </>
  );
};

const PostBulkActionButtons = (props) => (
  <>
    <ApproveResponses label="Approve Tasks" {...props} />
    {/* default bulk delete action */}
    <BulkDeleteButton {...props} />
  </>
);

export const ResponseList = (props) => (
  <List
    {...props}
    pagination={<BigPagination />}
    perPage={25}
    filters={<SearchFilter {...props} />}
    bulkActionButtons={<PostBulkActionButtons {...props} />}
    filter={{ Clan_id: props.clan.id }}
  >
    <Datagrid rowClick="show">
      <TextField source="response" />
      <BooleanField source="approved" />
      <ReferenceField label="Member" source="Member_id" reference="members">
        <TextField source="username" />
      </ReferenceField>
      <ReferenceField
        label="Question"
        source="Question_id"
        reference="Questions"
      >
        <TextField source="question" />
      </ReferenceField>
    </Datagrid>
  </List>
);

export const ResponseShow = (props) => (
  <Show {...props}>
    <TabbedShowLayout>
      <Tab label="Response">
        <TextField source="response" />
        <BooleanField source="approved" />
        <ReferenceField
          label="Member"
          source="Member_id"
          reference="members"
          alwaysOn
        >
          <TextField optionText="username" />
        </ReferenceField>
        <ReferenceField
          label="Question"
          source="Question_id"
          reference="Questions"
          alwaysOn
        >
          <TextField optionText="question" />
        </ReferenceField>
      </Tab>
    </TabbedShowLayout>
  </Show>
);

export const ResponseEdit = (props) => (
  <Edit {...props} undoable={false}>
    <SimpleForm>
      <TextInput source="response" />
      <BooleanInput source="approved" />
      <ReferenceInput
        label="Member"
        source="Member_id"
        reference="members"
        filter={{ Clan_id: props.clan.id }}
        alwaysOn
      >
        <AutocompleteInput optionText="username" />
      </ReferenceInput>
      <ReferenceInput
        label="Question"
        source="Question_id"
        reference="Questions"
        filter={{ Clan_id: props.clan.id }}
        alwaysOn
      >
        <AutocompleteInput optionText="question" />
      </ReferenceInput>
    </SimpleForm>
  </Edit>
);

export const ResponseCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="response" />
      <BooleanInput source="approved" />
      <ReferenceInput
        label="Member"
        source="Member_id"
        reference="members"
        filter={{ Clan_id: props.clan.id }}
        alwaysOn
      >
        <AutocompleteInput optionText="username" />
      </ReferenceInput>
      <ReferenceInput
        label="Question"
        source="Question_id"
        reference="Questions"
        filter={{ Clan_id: props.clan.id }}
        alwaysOn
      >
        <AutocompleteInput optionText="question" />
      </ReferenceInput>
      <NumberInput source="Clan_id" initialValue={props.clan.id} disabled />
    </SimpleForm>
  </Create>
);
