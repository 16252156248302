import React from "react";
import {
  List,
  Show,
  Pagination,
  Create,
  SimpleShowLayout,
  Datagrid,
  Edit,
  SimpleForm,
  NumberInput,
} from "react-admin";
import { JsonField, JsonInput } from "react-admin-json-view";

const BigPagination = (props) => (
  <Pagination rowsPerPageOptions={[25, 50, 100, 2000]} {...props} />
);

export const LanguageList = (props) => (
  <List {...props} pagination={<BigPagination />} filter={{ Clan_id: props.clan.id }}>
    <Datagrid rowClick="show">
      <JsonField
        source="content"
        addLabel={true}
        jsonString={false} // Set to true if the value is a string, default: false
        reactJsonOptions={{
          // Props passed to react-json-view
          name: null,
          collapsed: true,
          enableClipboard: false,
          displayDataTypes: false,
        }}
      />
    </Datagrid>
  </List>
);

export const LanguageShow = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <JsonField
        source="content"
        addLabel={true}
        jsonString={false} // Set to true if the value is a string, default: false
        reactJsonOptions={{
          // Props passed to react-json-view
          name: null,
          collapsed: true,
          enableClipboard: false,
          displayDataTypes: false,
        }}
      />
    </SimpleShowLayout>
  </Show>
);

export const LanguageEdit = (props) => (
  <Edit {...props} undoable={false}>
    <SimpleForm>
      <JsonInput
        source="content"
        jsonString={false} // Set to true if the value is a string, default: false
        reactJsonOptions={{
          // Props passed to react-json-view
          name: null,
          collapsed: true,
          enableClipboard: false,
          displayDataTypes: false,
        }}
      />
    </SimpleForm>
  </Edit>
);

export const LanguageCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <JsonInput
        source="content"
        jsonString={true} // Set to true if the value is a string, default: false
        reactJsonOptions={{
          // Props passed to react-json-view
          name: null,
          collapsed: true,
          enableClipboard: false,
          displayDataTypes: false,
        }}
      />
      <NumberInput source="Clan_id" initialValue={props.clan.id} disabled />
    </SimpleForm>
  </Create>
);
