import React from "react";
import { Link } from "react-router-dom";
import { ListItem, ListItemText, ListItemIcon } from "@mui/material";

export default function Header({ to, Icon, text, onClick, target }) {
    return (
        <ListItem
            button
            disableGutters
            disablePadding
            component={Link}
            to={to}
            onClick={onClick}
            target={target}
            dense // Reduces padding for a more compact appearance
            sx={{
                padding: 0,
                alignItems: "center",
                color: "inherit",
                position: "relative",
                fontWeight: "400",
                fontSize: "12px",
                textTransform: "uppercase",
                display: "inline-flex",
            }}
        >
            <ListItemIcon>
                <Icon />
            </ListItemIcon>
            <ListItemText
                margin={0}
                primaryTypographyProps={{ variant: "button" }}
                primary={text}
            />
        </ListItem>
    );
}
