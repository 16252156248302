import React, { useContext, useState } from "react";
import { SubmissionContext } from "../../contexts/submissionContext";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { useHistory } from "react-router-dom";
import { Box } from "@mui/material";

export default function Search({ baseUrl, onChange }) {
    const { members, fetchMembers } = useContext(SubmissionContext);
    const [member, setMember] = useState({});
    const history = useHistory();

    return (
        <Box
            sx={{
                maxWidth: "492px", // Reduced by 8px total (4px per side)
                borderRadius: 1, // Using theme shape default spacing
                margin: "auto",
            }}
        >
            <Autocomplete
                options={members}
                autoHighlight
                value={member}
                getOptionLabel={(option) => option.username || ""}
                sx={{ width: "calc(100% - 8px)", '& .MuiOutlinedInput-root': { p: 0, pl: '8px' } }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        fullWidth sx={{ p: "4px" }}
                        placeholder={"Find a member..."}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                )}
                onChange={(event, newMember) => {
                    setMember(newMember);
                    if (newMember) {
                        history.push(`${baseUrl}/lookup/${newMember.username}`);
                    }
                    if (onChange) {
                        onChange();
                    }
                }}
                onInputChange={(event, value) => {
                    fetchMembers(value);
                }}
            />
        </Box>
    );
}
