import {
  List,
  Filter,
  DateField,
  ImageField,
  DateInput,
  Pagination,
  Show,
  Create,
  SimpleShowLayout,
  Datagrid,
  TextField,
  TextInput,
  ReferenceField,
  ReferenceInput,
  AutocompleteInput,
  Edit,
  SimpleForm,
  NumberInput,
} from "react-admin";
import React from "react";

const BigPagination = (props) => (
  <Pagination rowsPerPageOptions={[25, 50, 100, 2000]} {...props} />
);
const SearchFilter = (props) => (
  <Filter {...props}>
    <ReferenceInput
      label="Member"
      source="Member_id"
      reference="members"
      filter={{ Clan_id: props.clan.id }}
      alwaysOn
    >
      <AutocompleteInput optionText="username" />
    </ReferenceInput>
    <TextInput source="offense" alwaysOn />
    <TextInput source="details" alwaysOn />
    <TextInput source="proof" alwaysOn />
    <ReferenceInput label="Admin" source="admin" reference="members" alwaysOn filter={{ Clan_id: props.clan.id }}>
      <AutocompleteInput optionText="username" />
    </ReferenceInput>
    <DateInput source="date" alwaysOn />
    <ReferenceInput label="Admin" source="admin" reference="members" alwaysOn filter={{ Clan_id: props.clan.id }}>
      <AutocompleteInput optionText="username" />
    </ReferenceInput>
  </Filter>
);
export const StrikeList = (props) => (
  <List
    {...props}
    filters={<SearchFilter {...props} />}
    pagination={<BigPagination />}
    sort={{ field: "date", order: "DESC" }}
    filter={{ Clan_id: props.clan.id }}
    perPage={25}
  >
    <Datagrid rowClick="show">
      <ReferenceField label="Member" source="Member_id" reference="members">
        <TextField source="username" />
      </ReferenceField>
      <TextField source="offense" />
      <TextField source="details" />
      <ImageField source="proof" />
      <ReferenceField label="Admin" source="admin" reference="members">
        <TextField source="username" />
      </ReferenceField>
      <DateField source="date" />
      <ReferenceField label="Admin" source="admin" reference="members">
        <TextField source="username" />
      </ReferenceField>
    </Datagrid>
  </List>
);

export const StrikeShow = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <ReferenceField label="Member" source="Member_id" reference="members">
        <TextField source="username" />
      </ReferenceField>
      <TextField source="offense" />
      <TextField source="details" />
      <ImageField source="proof" />
      <ReferenceField label="Admin" source="admin" reference="members">
        <TextField source="username" />
      </ReferenceField>
      <DateField source="date" />
      <ReferenceField label="Admin" source="admin" reference="members">
        <TextField source="username" />
      </ReferenceField>
    </SimpleShowLayout>
  </Show>
);

export const StrikeEdit = (props) => (
  <Edit {...props} undoable={false}>
    <SimpleForm>
      <ReferenceInput label="Member" source="Member_id" reference="members" filter={{ Clan_id: props.clan.id }}>
        <AutocompleteInput optionText="username" />
      </ReferenceInput>
      <TextInput source="offense" />
      <TextInput source="details" />
      <TextInput source="proof" />
      <ReferenceInput label="Admin" source="admin" reference="members" filter={{ Clan_id: props.clan.id, isAdmin: true }}>
        <AutocompleteInput optionText="username" />
      </ReferenceInput>
      <DateInput source="date" />
    </SimpleForm>
  </Edit>
);

export const StrikeCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <ReferenceInput label="Member" source="Member_id" reference="members" filter={{ Clan_id: props.clan.id }}>
        <AutocompleteInput optionText="username" />
      </ReferenceInput>
      <TextInput source="offense" />
      <TextInput source="details" />
      <TextInput source="proof" />
      <ReferenceInput label="Admin" source="admin" reference="members" filter={{ Clan_id: props.clan.id, isAdmin: true }}>
        <AutocompleteInput optionText="username" />
      </ReferenceInput>
      <DateInput source="date" />
      <NumberInput source="Clan_id" initialValue={props.clan.id} disabled />
    </SimpleForm>
  </Create>
);
