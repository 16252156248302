import React from "react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { red } from "@mui/material/colors";
import Countdown from "react-countdown";
import { useTranslation } from "react-i18next";

export default function EventCard({ event }) {
  const { t } = useTranslation();
  const date = new Date(event.date);

  const renderer = ({ days, hours, minutes, seconds }) => (
    <span>
      In {days} days and {hours}:{minutes}:{seconds} hours ({
        days * 14400 + hours * 600 + minutes * 100 + seconds * 0.6
      } ticks)
    </span>
  );

  return (
    <Card sx={{ boxShadow: 3, borderRadius: 2, p: 2 }}>
      <CardHeader
        avatar={
          <Avatar
            aria-label="event"
            sx={{ bgcolor: red[500] }}
            src={event.screenshot}
          >
            {event.screenshot ? null : event.name.charAt(0).toUpperCase()}
          </Avatar>
        }
        title={event.name}
        subheader={`${date.toLocaleString("default", {
          month: "long",
        })}  ${date.getDate()}, ${date.getFullYear()}, ${date.getHours()}:$` +
          `${date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()}`}
      />
      <CardContent>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            {date > new Date() && (
              <Typography
                variant="subtitle2"
                color="textSecondary"
                gutterBottom
              >
                <Countdown date={date} renderer={renderer} />
              </Typography>
            )}
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2" color="textSecondary">
              {t("events.eventCard.hosted")}: <strong>{event.member.username}</strong>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2" color="textSecondary">
              {event.description}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
