import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    ReferenceField,
    Pagination,
    DateField,
    DateInput,
    TextInput,
    ReferenceInput,
    SelectInput,
    Filter,
    Create,
    SimpleForm,
    Edit,
    Show,
    TabbedShowLayout,
    Tab,
} from 'react-admin';

const BingoSubmissionFilter = (props) => (
    <Filter {...props}>
        <ReferenceInput label="Progress" source="BingoProgress_id" reference="BingoProgresses">
            <SelectInput optionText="name" />
        </ReferenceInput>
        <DateInput label="Submission Date" source="submission_date" />
    </Filter>
);

const BigPagination = (props) => (
    <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />
);

export const BingoSubmissionList = (props) => (
    <List {...props} filters={<BingoSubmissionFilter />} pagination={<BigPagination />} perPage={25}>
        <Datagrid rowClick="show">
            <ReferenceField label="Progress" source="BingoProgress_id" reference="BingoProgresses">
                <TextField source="id" />
            </ReferenceField>
            <DateField source="submission_date" />
            <TextField source="status" />
            <TextField source="screenshot" />
        </Datagrid>
    </List>
);

export const BingoSubmissionShow = (props) => (
    <Show {...props}>
        <TabbedShowLayout>
            <Tab label="Details">
                <ReferenceField label="Progress" source="BingoProgress_id" reference="BingoProgresses">
                    <TextField source="id" />
                </ReferenceField>
                <DateField source="submission_date" />
                <TextField source="status" />
                <TextField source="screenshot" />
            </Tab>
        </TabbedShowLayout>
    </Show>
);

export const BingoSubmissionCreate = (props) => (
    <Create {...props}>
        <SimpleForm>
            <ReferenceInput label="Progress" source="BingoProgress_id" reference="BingoProgresses" required>
                <SelectInput optionText={(record) => record?.name ?? 'Unknown'} />
            </ReferenceInput>
            <DateInput source="submission_date" required />
            <SelectInput
                source="status"
                choices={[
                    { id: 'submitted', name: 'Submitted' },
                    { id: 'denied', name: 'Denied' },
                    { id: 'complete', name: 'Complete' },
                ]}
                required
            />
            <TextInput source="screenshot" />
        </SimpleForm>
    </Create>
);

export const BingoSubmissionEdit = (props) => (
    <Edit {...props}>
        <SimpleForm>
            <ReferenceInput label="Progress" source="BingoProgress_id" reference="BingoProgresses" required>
                <SelectInput optionText="name" />
            </ReferenceInput>
            <DateInput source="submission_date" required />
            <SelectInput
                source="status"
                choices={[
                    { id: 'submitted', name: 'Submitted' },
                    { id: 'denied', name: 'Denied' },
                    { id: 'complete', name: 'Complete' },
                ]}
                required
            />
            <TextInput source="screenshot" />
        </SimpleForm>
    </Edit>
);
