import React, { useState, useEffect, useCallback } from "react";

export default function Parallax({ image = "" }) {
  const [transform, setTransform] = useState(`translate3d(0,0px,0)`);

  const resetTransform = useCallback(() => {
    if (window.innerWidth >= 768) {
      const windowScrollTop = window.pageYOffset / 3;
      setTransform(`translate3d(0,${windowScrollTop}px,0)`);
    }
  }, []);

  useEffect(() => {
    if (window.innerWidth >= 768) {
      window.addEventListener("scroll", resetTransform);
    }
    return () => {
      window.removeEventListener("scroll", resetTransform);
    };
  }, [resetTransform]); // Added dependency array to avoid redundant renders

  return (
    <div
      style={{
        backgroundImage: `url(${image})`,
        height: "90vh",
        maxHeight: "1000px",
        overflow: "hidden",
        position: "relative",
        backgroundPosition: "center center",
        backgroundSize: "cover",
        margin: "0",
        padding: "0",
        border: "0",
        display: "flex",
        alignItems: "center",
        transform,
      }}
    />
  );
}
