import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    ReferenceField,
    Pagination,
    TextInput,
    ReferenceInput,
    SelectInput,
    Filter,
    Show,
    TabbedShowLayout,
    Tab,
    Create,
    SimpleForm,
    Edit,
    UrlField
} from 'react-admin';

const BingoTeamFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Team Name" source="name" alwaysOn />
        <ReferenceInput label="Board" source="BingoBoard_id" reference="bingo-boards" alwaysOn>
            <SelectInput optionText="name" />
        </ReferenceInput>
    </Filter>
);

const BigPagination = (props) => (
    <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />
);

export const BingoTeamList = (props) => (
    <List
        {...props}
        filters={<BingoTeamFilter />}
        pagination={<BigPagination />}
        perPage={25}
    >
        <Datagrid rowClick="show">
            <TextField source="name" label="Team Name" />
            <ReferenceField label="Board" source="BingoBoard_id" reference="BingoBoards">
                <TextField source="name" />
            </ReferenceField>
            <UrlField source="board_url" label="Board URL" />
            <UrlField source="logo_url" label="Logo URL" />
        </Datagrid>
    </List>
);

export const BingoTeamShow = (props) => (
    <Show {...props}>
        <TabbedShowLayout>
            <Tab label="Details">
                <TextField source="name" label="Team Name" />
                <ReferenceField label="Board" source="BingoBoard_id" reference="BingoBoards">
                    <TextField source="name" />
                </ReferenceField>
                <UrlField source="logo_url" label="Logo URL" />
                <UrlField source="board_url" label="Board URL" />
            </Tab>
            <Tab label="Members">
                <ReferenceField reference="bingo-members" source="BingoTeam_id">
                    <TextField source="name" />
                </ReferenceField>
            </Tab>
        </TabbedShowLayout>
    </Show>
);

export const BingoTeamCreate = (props) => (
    <Create {...props}>
        <SimpleForm>
            <TextInput label="Team Name" source="name" />
            <TextInput label="Board URL" source="board_url" />
            <TextInput label="Logo URL" source="logo_url" />
            <ReferenceInput label="Board" source="BingoBoard_id" reference="BingoBoards">
                <SelectInput optionText="name" />
            </ReferenceInput>
        </SimpleForm>
    </Create>
);

export const BingoTeamEdit = (props) => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput label="Team Name" source="name" />
            <TextInput label="Board URL" source="board_url" />
            <TextInput label="Logo URL" source="logo_url" />
            <ReferenceInput label="Board" source="BingoBoard_id" reference="BingoBoards">
                <SelectInput optionText="name" />
            </ReferenceInput>
        </SimpleForm>
    </Edit>
);