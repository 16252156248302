import {
  List,
  useRedirect,
  useUpdate,
  Filter,
  TopToolbar,
  TabbedForm,
  TabbedShowLayout,
  Tab,
  FormTab,
  ReferenceArrayField,
  SingleFieldList,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  Button,
  DateField,
  ImageField,
  DateInput,
  Confirm,
  useUpdateMany,
  useRefresh,
  useNotify,
  useUnselectAll,
  Pagination,
  UrlField,
  Show,
  Create,
  EditButton,
  Datagrid,
  BooleanField,
  NumberField,
  TextField,
  NumberInput,
  TextInput,
  ReferenceField,
  BooleanInput,
  ReferenceInput,
  AutocompleteInput,
  Edit,
  BulkDeleteButton,
} from "react-admin";
import React, { Fragment, useState } from "react";

const ApproveDrops = ({ selectedIds, resource }) => {
  const [open, setOpen] = useState(false);
  const refresh = useRefresh();
  const notify = useNotify();
  const unselectAll = useUnselectAll();
  const [updateMany, { loading }] = useUpdateMany(
    "drops",
    selectedIds,
    { approved: true },
    {
      onSuccess: () => {
        refresh();
        notify("Drops approved!");
        unselectAll(resource);
      },
      onFailure: () => notify("Error: Drops not approved", "warning"),
    }
  );
  const handleClick = () => setOpen(true);
  const handleDialogClose = () => setOpen(false);

  const handleConfirm = () => {
    updateMany();
    setOpen(false);
  };

  return (
    <>
      <Button label='Approve Drops' onClick={handleClick} />
      <Confirm
        isOpen={open}
        loading={loading}
        title='Approve Drops'
        content='Are you sure you want to approve these drops?'
        onConfirm={handleConfirm}
        onClose={handleDialogClose}
      />
    </>
  );
};

const DenyDrops = ({ selectedIds, resource }) => {
  const [open, setOpen] = useState(false);
  const refresh = useRefresh();
  const notify = useNotify();
  const unselectAll = useUnselectAll();
  const [updateMany, { loading }] = useUpdateMany(
    "drops",
    selectedIds,
    { approved: false },
    {
      onSuccess: () => {
        refresh();
        notify("Drops denied!");
        unselectAll(resource);
      },
      onFailure: () => notify("Error: Drops not denied", "warning"),
    }
  );
  const handleClick = () => setOpen(true);
  const handleDialogClose = () => setOpen(false);

  const handleConfirm = () => {
    updateMany();
    setOpen(false);
  };

  return (
    <>
      <Button label='Deny Drops' onClick={handleClick} />
      <Confirm
        isOpen={open}
        loading={loading}
        title='Deny Drops'
        content='Are you sure you want to deny these drops?'
        onConfirm={handleConfirm}
        onClose={handleDialogClose}
      />
    </>
  );
};

const ApproveButton = ({ record }) => {
  const refresh = useRefresh();
  const notify = useNotify();
  const redirect = useRedirect();
  const [approve, { loading }] = useUpdate(
    "drops",
    record ? record.id : null,
    { approved: true },
    {
      onSuccess: () => {
        console.log("success");
        redirect("/drops");
        refresh();
        notify("Drop approved!");
      },
      onFailure: (error) => notify(`Error: ${error.message}`, "warning"),
    }
  );
  return <Button label='Approve' onClick={approve} disabled={loading} />;
};

const DenyButton = ({ record }) => {
  const refresh = useRefresh();
  const notify = useNotify();
  const redirect = useRedirect();
  const [Deny, { loading }] = useUpdate(
    "drops",
    record ? record.id : null,
    { approved: false },
    {
      onSuccess: () => {
        console.log("success");
        redirect("/drops");
        refresh();
        notify("Drop denied!");
      },
      onFailure: (error) => notify(`Error: ${error.message}`, "warning"),
    }
  );
  return <Button label='Deny' onClick={Deny} disabled={loading} />;
};

const PostBulkActionButtons = (props) => (
  <>
    <ApproveDrops label='Approve Drops' {...props} />
    <DenyDrops {...props} />
    <BulkDeleteButton {...props} />
  </>
);

const BigPagination = (props) => (
  <Pagination rowsPerPageOptions={[25, 50, 100, 2000]} {...props} />
);
const SearchFilter = (props) => (
  <Filter {...props}>
    <ReferenceInput
      label='Attack'
      source='attack'
      reference='members'
      alwaysOn
      filter={{ Clan_id: props.clan.id }}
    >
      <AutocompleteInput optionText='username' />
    </ReferenceInput>
    <ReferenceInput
      label='Tank'
      source='tank'
      reference='members'
      alwaysOn
      filter={{ Clan_id: props.clan.id }}
    >
      <AutocompleteInput optionText='username' />
    </ReferenceInput>
    <ReferenceInput
      label='Item'
      source='Item_id'
      reference='items'
      alwaysOn
      filter={{ Clan_id: props.clan.id }}
    >
      <AutocompleteInput optionText='name' />
    </ReferenceInput>
    <ReferenceInput
      label='Admin'
      source='admin'
      reference='members'
      alwaysOn
      filter={{ Clan_id: props.clan.id }}
    >
      <AutocompleteInput optionText='username' />
    </ReferenceInput>
    <ReferenceInput
      label='Submitter'
      source='submitter'
      reference='members'
      filter={{ Clan_id: props.clan.id }}
      alwaysOn
    >
      <AutocompleteInput
        optionText='username'
        allowEmpty
        emptyText='-- none --'
      />
    </ReferenceInput>
    <DateInput source='date' alwaysOn />
  </Filter>
);
export const DropList = (props) => (
  <List
    {...props}
    bulkActionButtons={<PostBulkActionButtons {...props} />}
    filters={<SearchFilter {...props} />}
    pagination={<BigPagination />}
    sort={{ field: "date", order: "DESC" }}
    perPage={25}
    filter={{ Clan_id: props.clan.id }}
  >
    <Datagrid rowClick='show'>
      <ReferenceField label='Item' source='Item_id' reference='items'>
        <TextField source='name' />
      </ReferenceField>
      <ReferenceField label='Attack' source='attack' reference='members'>
        <TextField source='username' />
      </ReferenceField>
      <ReferenceField label='Tank' source='tank' reference='members'>
        <TextField source='username' />
      </ReferenceField>
      <NumberField label='Attack Points' source='pointsAttack' />
      <NumberField label='Tank Points' source='pointsTank' />
      <TextField source='value' />
      <TextField source='attendees' />
      <DateField source='date' />
      <ImageField source='screenshot' />
      <BooleanField source='approved' />
      <BooleanField source='auto' />
      <ReferenceField label='Submitter' source='submitter' reference='members'>
        <TextField source='username' />
      </ReferenceField>
      <ReferenceField label='Admin' source='admin' reference='members'>
        <TextField source='username' />
      </ReferenceField>
    </Datagrid>
  </List>
);

const ShowActions = ({ basePath, data }) => (
  <TopToolbar>
    <EditButton basePath={basePath} record={data} />
    <ApproveButton basePath={basePath} record={data} />
    <DenyButton basePath={basePath} record={data} />
  </TopToolbar>
);

export const DropShow = (props) => (
  <Show actions={<ShowActions />} {...props}>
    <TabbedShowLayout>
      <Tab label='drop'>
        <ReferenceField label='Attack' source='attack' reference='members'>
          <TextField source='username' />
        </ReferenceField>
        <ReferenceField label='Tank' source='tank' reference='members'>
          <TextField source='username' />
        </ReferenceField>
        <ReferenceField label='Item' source='Item_id' reference='items'>
          <TextField source='name' />
        </ReferenceField>
        <ReferenceField label='Admin' source='admin' reference='members'>
          <TextField source='username' />
        </ReferenceField>
        <ReferenceField
          label='Submitter'
          source='submitter'
          reference='members'
        >
          <TextField source='username' />
        </ReferenceField>
        <UrlField source='screenshot' />
        <DateField source='date' />
        <TextField source='value' />
        <TextField source='attendees' />
        <TextField source='comments' />
        <NumberField label='Attack Points' source='pointsAttack' />
        <NumberField label='Tank Points' source='pointsTank' />
        <BooleanField source='approved' />
        <BooleanField source='auto' />
      </Tab>
      <Tab label='alts' path='alts'>
        <ReferenceArrayField source='alts' reference='members'>
          <SingleFieldList>
            <TextField source='username' />
          </SingleFieldList>
        </ReferenceArrayField>
      </Tab>
    </TabbedShowLayout>
  </Show>
);

export const DropEdit = (props) => (
  <Edit {...props} undoable={false}>
    <TabbedForm>
      <FormTab label='drop'>
        <ReferenceInput
          label='Attack'
          source='attack'
          reference='members'
          filter={{ Clan_id: props.clan.id }}
          allowEmpty
        >
          <AutocompleteInput
            optionText='username'
            allowEmpty
            emptyText='-- none --'
          />
        </ReferenceInput>
        <ReferenceInput
          label='Tank'
          source='tank'
          reference='members'
          filter={{ Clan_id: props.clan.id }}
          allowEmpty
        >
          <AutocompleteInput
            optionText='username'
            allowEmpty
            emptyText='-- none --'
          />
        </ReferenceInput>
        <ReferenceInput
          label='Item'
          source='Item_id'
          reference='items'
          filter={{ Clan_id: props.clan.id }}
          allowEmpty
        >
          <AutocompleteInput
            optionText='name'
            allowEmpty
            emptyText='-- none --'
          />
        </ReferenceInput>
        <ReferenceInput
          label='Submitter'
          source='submitter'
          reference='members'
          filter={{ Clan_id: props.clan.id }}
          allowEmpty
        >
          <AutocompleteInput
            optionText='username'
            allowEmpty
            emptyText='-- none --'
          />
        </ReferenceInput>
        <TextInput source='screenshot' />
        <DateInput source='date' />
        <TextInput source='attendees' allowEmpty emptyText='Not Recorded' />
        <TextInput source='value' />
        <TextInput multiline source='comments' />
        <NumberInput label='Attack Points' source='pointsAttack' />
        <NumberInput label='Tank Points' source='pointsTank' />
        <BooleanInput source='approved' />
        <BooleanInput source='auto' />
      </FormTab>
      <FormTab label='alts' path='alts'>
        <ReferenceArrayInput
          source='alts'
          reference='members'
          filter={{ Clan_id: props.clan.id }}
        >
          <AutocompleteArrayInput optionText='username' />
        </ReferenceArrayInput>
      </FormTab>
    </TabbedForm>
  </Edit>
);

export const DropCreate = (props) => (
  <Create {...props}>
    <TabbedForm>
      <FormTab label='drop'>
        <ReferenceInput
          label='Attack'
          source='attack'
          reference='members'
          filter={{ Clan_id: props.clan.id }}
          allowEmpty
        >
          <AutocompleteInput
            optionText='username'
            allowEmpty
            emptyText='-- none --'
          />
        </ReferenceInput>
        <ReferenceInput
          label='Tank'
          source='tank'
          reference='members'
          filter={{ Clan_id: props.clan.id }}
          allowEmpty
        >
          <AutocompleteInput
            optionText='username'
            allowEmpty
            emptyText='-- none --'
          />
        </ReferenceInput>
        <ReferenceInput
          label='Alt'
          source='alt'
          reference='members'
          allowEmpty
          filter={{ Clan_id: props.clan.id }}
        >
          <AutocompleteInput
            optionText='username'
            allowEmpty
            emptyText='-- none --'
          />
        </ReferenceInput>
        <ReferenceInput
          label='Item'
          source='Item_id'
          reference='items'
          filter={{ Clan_id: props.clan.id }}
          allowEmpty
        >
          <AutocompleteInput
            optionText='name'
            allowEmpty
            emptyText='-- none --'
          />
        </ReferenceInput>
        <ReferenceInput
          label='Submitter'
          source='submitter'
          reference='members'
          filter={{ Clan_id: props.clan.id }}
          allowEmpty
        >
          <AutocompleteInput
            optionText='username'
            allowEmpty
            emptyText='-- none --'
          />
        </ReferenceInput>
        <TextInput source='screenshot' />
        <DateInput source='date' defaultValue={new Date().toISOString()} />
        <TextInput source='value' />
        <TextInput source='attendees' allowEmpty />
        <NumberInput label='Attack Points' source='pointsAttack' />
        <NumberInput label='Tank Points' source='pointsTank' />
        <BooleanInput source='approved' />
        <BooleanInput source='auto' />
        <NumberInput source='Clan_id' initialValue={props.clan.id} disabled />
      </FormTab>
      <FormTab label='alts' path='alts'>
        <ReferenceArrayInput
          source='alts'
          reference='members'
          filter={{ Clan_id: props.clan.id }}
        >
          <AutocompleteArrayInput optionText='username' />
        </ReferenceArrayInput>
      </FormTab>
    </TabbedForm>
  </Create>
);
