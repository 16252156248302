import React, { FunctionComponent } from "react";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from "@material-ui/core/Typography";

interface ExpansionCardProps {
    title: string;
    children: JSX.Element
}

const CenteredGrid: FunctionComponent<ExpansionCardProps> = ({ title, children }) => {

    return (
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} >
            <Typography>{title}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {children}
          </AccordionDetails>
        </Accordion>
    );
}

export default CenteredGrid
