import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import Hidden from "@material-ui/core/Hidden";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import styles from "../../assets/jss/components/headerStyle";
import { UserContext } from "../../contexts/userContext";
import { ClanContext } from "../../contexts/clanContext";
import { ThemeContext } from "../../contexts/themeContext";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import MemberSearch from "./MemberSearch";
import Submissions from "./Submissions";
import SearchIcon from "@material-ui/icons/Search";
import Modal from "@material-ui/core/Modal";
import MenuIcon from "@material-ui/icons/Menu";
import Brightness4Icon from "@material-ui/icons/Brightness4";
import Brightness7Icon from '@material-ui/icons/Brightness7';
import { grey } from '@material-ui/core/colors';

export default function Toolbars({ handleDrawerToggle }) {
  const { user } = useContext(UserContext);
  const { mode, toggleDarkTheme } = useContext(ThemeContext);
  const { clan } = useContext(ClanContext);
  const useStyles = makeStyles(styles);
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [searchOpen, setSearchOpen] = React.useState(false);
  const handleSearchToggle = () => {
    setSearchOpen(!searchOpen);
  };
  const baseUrl = "";
  const brandComponent = (
    <Button>
      <Link
        to="/"
        style={{ fontWeight: "bold", display: "flex", alignItems: "center" }}
      >
        <img
          src={clan.clanLogo || process.env.REACT_APP_OG_IMAGE}
          alt={`${clan.name || "Header"} Logo`}
          height="30px"
          style={{ paddingRight: "7px" }}
        />
        <Typography variant="body1" noWrap color="textPrimary">
          {clan.name || process.env.REACT_APP_CLAN_NAME}
        </Typography>
      </Link>
    </Button>
  );

  return (
    <>
      <Hidden mdUp implementation="css">
        <Toolbar className={classes.container}>
          <div style={{ flex: 1, display: "flex" }}>{brandComponent}</div>
          <div style={{ flex: 1, display: "flex", justifyContent: "flex-end" }}>
            <IconButton
              color="inherit"
              aria-label="open search"
              onClick={handleSearchToggle}
            >
              <SearchIcon />
            </IconButton>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerToggle}
            >
              <MenuIcon />
            </IconButton>
            <Modal open={searchOpen} onClose={handleSearchToggle}>
              <div className={classes.paper}>
                <MemberSearch baseUrl={baseUrl} onChange={handleSearchToggle} />
              </div>
            </Modal>
          </div>
        </Toolbar>
      </Hidden>

      <Hidden smDown implementation="css">
        <Toolbar className={classes.container}>
          <div style={{ flex: 1, display: "flex" }}>{brandComponent}</div>
          <div style={{ flex: 1 }}>
            <MemberSearch baseUrl={baseUrl} />
          </div>
          <div style={{ flex: 1, display: "flex", justifyContent: "flex-end" }}>
            <IconButton
              onClick={() => toggleDarkTheme()}
            >
              {mode === "dark" ? <Brightness7Icon /> : <Brightness4Icon style={{ color: grey[900] }} />}
            </IconButton>
            {user.id ? (
              <>
                {user.Clan_id === clan.id && (
                  <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={handleClick}
                  >
                    <AddCircleOutlineIcon />
                  </IconButton>
                )}
                <Menu
                  id="simple-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={anchorEl}
                  onClose={handleClose}
                >
                  <Submissions baseUrl={baseUrl} onClick={handleClose} />
                </Menu>
                <Divider orientation="vertical" flexItem />
                <Link
                  to={`${baseUrl}/lookup/${user.username}`}
                  className={`${classes.navLink}`}
                >
                  <Typography
                    variant="subtitle2"
                    color={user.Clan_id === clan.id ? "inherit" : "error"}
                  >
                    {user.username}
                  </Typography>
                </Link>
                <Divider orientation="vertical" flexItem />
              </>
            ) : (
              <>
                <Divider orientation="vertical" flexItem />
                <Link to={`${baseUrl}/login`} className={`${classes.navLink}`}>
                  <Typography variant="subtitle2">Login</Typography>
                </Link>
                <Divider orientation="vertical" flexItem />
              </>
            )}
            <Modal open={searchOpen} onClose={handleSearchToggle}>
              <div className={classes.paper}>
                <MemberSearch baseUrl={baseUrl} onChange={handleSearchToggle} />
              </div>
            </Modal>
          </div>
        </Toolbar>
      </Hidden>
    </>
  );
}
