import React, { useEffect, useContext, useState } from "react";
import { Card, CardContent, Typography, Box } from "@mui/material";
import { SubmissionContext } from "../../contexts/submissionContext";

export default function ApplicationResponses({ record }) {
  const { fetchResponses } = useContext(SubmissionContext);
  const [responses, setResponses] = useState(undefined);

  const validURL = (str) => {
    var pattern = new RegExp(
      "^(https?:\\/\\/)?" +
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" +
      "((\\d{1,3}\\.){3}\\d{1,3}))" +
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" +
      "(\\?[;&a-z\\d%_.~+=-]*)?" +
      "(\\#[-a-z\\d_]*)?$",
      "i"
    );
    return !!pattern.test(str);
  };

  useEffect(() => {
    const loadResponses = async () =>
      setResponses(await fetchResponses(record.id));
    if (responses === undefined && record && record.id) {
      loadResponses(record.id);
    }
  }, [responses, record, fetchResponses]);

  return (
    <Card sx={{ display: "flex" }}>
      <CardContent sx={{ width: "100%" }}>
        {record && (
          <Box>
            <Box sx={{ mb: 2 }}>
              <Typography variant="h6">
                {responses && responses.length > 0
                  ? "Application"
                  : "No application"}
              </Typography>
            </Box>
            {responses &&
              responses.map((response) => (
                <Box key={response.id} sx={{ mb: 2 }}>
                  <Typography variant="body2">
                    {response.question.question}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{
                      width: "100%",
                      wordBreak: "break-all",
                      mt: 1,
                    }}
                  >
                    {validURL(response.response) ? (
                      <a href={response.response}>{response.response}</a>
                    ) : (
                      <b>{response.response}</b>
                    )}
                  </Typography>
                </Box>
              ))}
          </Box>
        )}
      </CardContent>
    </Card>
  );
}
