import React, { Fragment, useState } from "react";
import {
  List,
  useRedirect,
  useUpdate,
  TopToolbar,
  Filter,
  TabbedForm,
  TabbedShowLayout,
  Tab,
  FormTab,
  ReferenceArrayField,
  SingleFieldList,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  Button,
  DateField,
  ImageField,
  DateInput,
  Confirm,
  useUpdateMany,
  useRefresh,
  useNotify,
  useUnselectAll,
  Pagination,
  BulkDeleteButton,
  Show,
  Create,
  EditButton,
  Datagrid,
  BooleanField,
  NumberField,
  TextField,
  NumberInput,
  TextInput,
  ReferenceField,
  BooleanInput,
  ReferenceInput,
  AutocompleteInput,
  Edit,
  SimpleForm,
} from "react-admin";

const BigPagination = (props) => (
  <Pagination rowsPerPageOptions={[25, 50, 100, 2000]} {...props} />
);

const ApproveMentors = ({ selectedIds, resource }) => {
  const [open, setOpen] = useState(false);
  const refresh = useRefresh();
  const notify = useNotify();
  const unselectAll = useUnselectAll();
  const [updateMany, { loading }] = useUpdateMany(
    "Mentors",
    selectedIds,
    { approved: true },
    {
      onSuccess: () => {
        refresh();
        notify("Mentors approved!");
        unselectAll(resource);
      },
      onFailure: () => notify("Error: Mentors not approved", "warning"),
    }
  );
  const handleClick = () => setOpen(true);
  const handleDialogClose = () => setOpen(false);

  const handleConfirm = () => {
    updateMany();
    setOpen(false);
  };
  return (
    <>
      <Button label="Approve" onClick={handleClick} />
      <Confirm
        isOpen={open}
        loading={loading}
        title="Approve Mentors"
        content="Are you sure you want to approve these mentored raids?"
        onConfirm={handleConfirm}
        onClose={handleDialogClose}
      />
    </>
  );
};

const DenyMentors = ({ selectedIds, resource }) => {
  const [open, setOpen] = useState(false);
  const refresh = useRefresh();
  const notify = useNotify();
  const unselectAll = useUnselectAll();
  const [updateMany, { loading }] = useUpdateMany(
    "Mentors",
    selectedIds,
    { approved: false },
    {
      onSuccess: () => {
        refresh();
        notify("Mentors denied!");
        unselectAll(resource);
      },
      onFailure: () => notify("Error: Mentors not denied", "warning"),
    }
  );
  const handleClick = () => setOpen(true);
  const handleDialogClose = () => setOpen(false);

  const handleConfirm = () => {
    updateMany();
    setOpen(false);
  };

  return (
    <>
      <Button label="Deny" onClick={handleClick} />
      <Confirm
        isOpen={open}
        loading={loading}
        title="Deny Mentors"
        content="Are you sure you want to deny these mentors?"
        onConfirm={handleConfirm}
        onClose={handleDialogClose}
      />
    </>
  );
};

const ApproveButton = ({ record }) => {
  const refresh = useRefresh();
  const notify = useNotify();
  const redirect = useRedirect();
  const [approve, { loading }] = useUpdate(
    "Mentors",
    record ? record.id : null,
    { approved: true },
    {
      onSuccess: () => {
        console.log("success");
        redirect("/drops");
        refresh();
        notify("Mentor approved!");
      },
      onFailure: (error) => notify(`Error: ${error.message}`, "warning"),
    }
  );
  return <Button label="Approve" onClick={approve} disabled={loading} />;
};

const DenyButton = ({ record }) => {
  const refresh = useRefresh();
  const notify = useNotify();
  const redirect = useRedirect();
  const [Deny, { loading }] = useUpdate(
    "Mentors",
    record ? record.id : null,
    { approved: false },
    {
      onSuccess: () => {
        console.log("success");
        redirect("/drops");
        refresh();
        notify("Mentor denied!");
      },
      onFailure: (error) => notify(`Error: ${error.message}`, "warning"),
    }
  );
  return <Button label="Deny" onClick={Deny} disabled={loading} />;
};

const PostBulkActionButtons = (props) => (
  <>
    <ApproveMentors {...props} />
    <DenyMentors {...props} />
    {/* default bulk delete action */}
    <BulkDeleteButton {...props} />
  </>
);

const SearchFilter = (props) => (
  <Filter {...props}>
    <TextInput source="name" alwaysOn />
    <DateInput source="date" alwaysOn />
    <ReferenceInput
      label="Mentor Program"
      source="MentorProgram_id"
      reference="MentorPrograms"
      filter={{ Clan_id: props.clan.id }}
      allowEmpty
      alwaysOn
    >
      <AutocompleteInput optionText="name" allowEmpty emptyText="-- none --" />
    </ReferenceInput>
    <ReferenceInput
      label="Host"
      source="Member_id"
      reference="members"
      filter={{ Clan_id: props.clan.id }}
      alwaysOn
    >
      <AutocompleteInput optionText="username" />
    </ReferenceInput>
    <ReferenceInput label="Admin" source="admin" reference="members" alwaysOn filter={{ Clan_id: props.clan.id }}>
      <AutocompleteInput optionText="username" />
    </ReferenceInput>
    <ReferenceInput
      label="Submitter"
      source="submitter"
      reference="members"
      filter={{ Clan_id: props.clan.id }}
      alwaysOn
    >
      <AutocompleteInput
        optionText="username"
        allowEmpty
        emptyText="-- none --"
      />
    </ReferenceInput>
  </Filter>
);

const ShowActions = ({ basePath, data }) => (
  <TopToolbar>
    <EditButton basePath={basePath} record={data} />
    <ApproveButton basePath={basePath} record={data} />
    <DenyButton basePath={basePath} record={data} />
  </TopToolbar>
);

export const MentorList = (props) => (
  <List
    {...props}
    pagination={<BigPagination />}
    perPage={25}
    filters={<SearchFilter {...props} />}
    bulkActionButtons={<PostBulkActionButtons {...props} />}
    sort={{ field: "date", order: "DESC" }}
    filter={{ Clan_id: props.clan.id }}
  >
    <Datagrid rowClick="show">
      <TextField source="name" />
      <DateField source="date" />
      <NumberField source="completions" />
      <ImageField source="screenshot" />
      <ReferenceField
        label="Mentor Program"
        source="MentorProgram_id"
        reference="MentorPrograms"
      >
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField label="Host" source="Member_id" reference="members">
        <TextField source="username" />
      </ReferenceField>
      <BooleanField source="approved" />
      <BooleanField source="auto" />
      <ReferenceField label="Admin" source="admin" reference="members">
        <TextField source="username" />
      </ReferenceField>
      <ReferenceField label="Submitter" source="submitter" reference="members">
        <TextField source="username" />
      </ReferenceField>
    </Datagrid>
  </List>
);

export const MentorShow = (props) => (
  <Show {...props} actions={<ShowActions />}>
    <TabbedShowLayout>
      <Tab label="Mentor">
        <TextField source="name" />
        <DateField source="date" />
        <NumberField source="completions" />
        <ReferenceField
          label="Mentor Programs"
          source="MentorPrograms_id"
          reference="MentorPrograms"
        >
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField label="Host" source="Member_id" reference="members">
          <TextField source="username" />
        </ReferenceField>
        <ReferenceField
          label="Submitter"
          source="submitter"
          reference="members"
        >
          <TextField source="username" />
        </ReferenceField>
        <TextField source="screenshot" />
        <BooleanField source="approved" />
        <BooleanField source="auto" />
        <ReferenceField label="Admin" source="admin" reference="members">
          <TextField source="username" />
        </ReferenceField>
      </Tab>
      <Tab label="Helpers" path="attendance">
        <ReferenceArrayField source="attendees" reference="members">
          <SingleFieldList>
            <TextField source="username" />
          </SingleFieldList>
        </ReferenceArrayField>
      </Tab>
    </TabbedShowLayout>
  </Show>
);

export const MentorEdit = (props) => (
  <Edit {...props} undoable={false}>
    <TabbedForm>
      <FormTab label="Mentor">
        <TextInput source="name" />
        <DateInput source="date" />
        <NumberInput source="completions" />
        <ReferenceInput
          label="Mentor Program"
          source="MentorProgram_id"
          reference="MentorPrograms"
          filter={{ Clan_id: props.clan.id }}
        >
          <AutocompleteInput optionText="name" />
        </ReferenceInput>
        <ReferenceInput
          label="Host"
          source="Member_id"
          reference="members"
          filter={{ Clan_id: props.clan.id }}
          allowEmpty
        >
          <AutocompleteInput optionText="username" />
        </ReferenceInput>
        <ReferenceInput
          label="Submitter"
          source="submitter"
          reference="members"
          filter={{ Clan_id: props.clan.id }}
          allowEmpty
        >
          <AutocompleteInput
            optionText="username"
            allowEmpty
            emptyText="-- none --"
          />
        </ReferenceInput>
        <TextInput source="screenshot" />
        <BooleanInput source="approved" />
        <BooleanInput source="auto" />
      </FormTab>
      <FormTab label="Helpers" path="attendance">
        <ReferenceArrayInput source="attendees" reference="members" filter={{ Clan_id: props.clan.id }}>
          <AutocompleteArrayInput optionText="username" />
        </ReferenceArrayInput>
      </FormTab>
    </TabbedForm>
  </Edit>
);

export const MentorCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="name" />
      <DateInput source="date" />
      <NumberInput source="completions" />
      <ReferenceInput
        label="Mentor Program"
        source="MentorProgram_id"
        reference="MentorPrograms"
        filter={{ Clan_id: props.clan.id }}
      >
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
      <ReferenceInput
        label="Host"
        source="Member_id"
        reference="members"
        filter={{ Clan_id: props.clan.id }}
        allowEmpty
      >
        <AutocompleteInput optionText="username" />
      </ReferenceInput>
      <ReferenceInput
        label="Submitter"
        source="submitter"
        reference="members"
        filter={{ Clan_id: props.clan.id }}
        allowEmpty
      >
        <AutocompleteInput
          optionText="username"
          allowEmpty
          emptyText="-- none --"
        />
      </ReferenceInput>
      <TextInput source="screenshot" />
      <BooleanInput source="approved" />
      <BooleanInput source="auto" />
      <NumberInput source="Clan_id" initialValue={props.clan.id} disabled />
    </SimpleForm>
  </Create>
);
