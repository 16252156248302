import React from "react";
import { Resource } from "react-admin";
import { DropList, DropShow, DropEdit, DropCreate } from "../models/Drops";
import { ItemList, ItemShow, ItemEdit, ItemCreate } from "../models/Items";
import {
  MemberList,
  MemberShow,
  MemberEdit,
  MemberCreate,
} from "../models/Members";
import { EventList, EventShow, EventEdit, EventCreate } from "../models/Events";
import { TimeList, TimeShow, TimeEdit, TimeCreate } from "../models/Times";
import { RankList, RankShow, RankEdit, RankCreate } from "../models/Ranks";
import {
  MentorList,
  MentorShow,
  MentorEdit,
  MentorCreate,
} from "../models/Mentors";
import {
  ScrapeList,
  ScrapeShow,
  ScrapeEdit,
  ScrapeCreate,
} from "../models/Scrapes";
import { BadgeList, BadgeShow, BadgeEdit, BadgeCreate } from "../models/Badges";
import { TaskList, TaskShow, TaskEdit, TaskCreate } from "../models/Tasks";
import {
  TaskCompletedList,
  TaskCompletedShow,
  TaskCompletedEdit,
  TaskCompletedCreate,
} from "../models/TaskCompleted";
import {
  QuestionList,
  QuestionShow,
  QuestionEdit,
  QuestionCreate,
} from "../models/Questions";
import {
  ResponseList,
  ResponseShow,
  ResponseEdit,
  ResponseCreate,
} from "../models/Responses";
import {
  ChecklistList,
  ChecklistShow,
  ChecklistEdit,
  ChecklistCreate,
} from "../models/Checklists";
import {
  StrikeList,
  StrikeShow,
  StrikeEdit,
  StrikeCreate,
} from "../models/Strikes";
import {
  DonationList,
  DonationShow,
  DonationEdit,
  DonationCreate,
} from "../models/Donations";
import { PointList, PointShow, PointEdit, PointCreate } from "../models/Points";
import {
  PointCategoryList,
  PointCategoryShow,
  PointCategoryEdit,
  PointCategoryCreate,
} from "../models/PointCategories";
import {
  PointTransactionList,
  PointTransactionShow,
  PointTransactionEdit,
  PointTransactionCreate,
} from "../models/PointTransactions";
import {
  MentorProgramList,
  MentorProgramShow,
  MentorProgramEdit,
  MentorProgramCreate,
} from "../models/MentorPrograms";
import {
  TeacherList,
  TeacherShow,
  TeacherEdit,
  TeacherCreate,
} from "../models/Teachers";
import {
  StudentList,
  StudentShow,
  StudentEdit,
  StudentCreate,
} from "../models/Students";
import {
  LanguageList,
  LanguageShow,
  LanguageEdit,
  LanguageCreate,
} from "../models/Languages";
import {
  DiscordList,
  DiscordShow,
  DiscordEdit,
  DiscordCreate,
} from "../models/Discords";
import { BossList, BossShow, BossEdit, BossCreate } from "../models/Bosses";
import { ClanList, ClanShow, ClanEdit } from "../models/Clans";
import {
  RecordList,
  RecordShow,
  RecordEdit,
  RecordCreate,
} from "../models/Records";
import {
  AuditList,
  AuditShow,
  AuditEdit,
} from "../models/Audits";
import {
  SuggestionList,
  SuggestionShow,
  SuggestionEdit,
  SuggestionCreate,
} from "../models/Suggestions";
import {
  BingoBoardList,
  BingoBoardShow,
  BingoBoardEdit,
  BingoBoardCreate,
} from "../models/BingoBoard";
import {
  BingoTeamList,
  BingoTeamShow,
  BingoTeamEdit,
  BingoTeamCreate,
} from "../models/BingoTeam";
import {
  BingoTileList,
  BingoTileShow,
  BingoTileEdit,
  BingoTileCreate,
} from "../models/BingoTile";
import {
  BingoSubmissionCreate,
  BingoSubmissionEdit,
  BingoSubmissionList,
  BingoSubmissionShow,
} from "../models/BingoSubmission";
import {
  BingoProgressCreate,
  BingoProgressEdit,
  BingoProgressList,
  BingoProgressShow,
} from "../models/BingoProgress";

const adminRoutes = (clan) => [
  <Resource
    name='drops'
    key='drops'
    list={(props) => <DropList clan={clan} {...props} />}
    show={DropShow}
    edit={(props) => <DropEdit clan={clan} {...props} />}
    create={(props) => <DropCreate clan={clan} {...props} />}
  />,
  <Resource
    name='members'
    key='members'
    list={(props) => <MemberList clan={clan} {...props} />}
    show={MemberShow}
    edit={(props) => <MemberEdit clan={clan} {...props} />}
    create={(props) => <MemberCreate clan={clan} {...props} />}
  />,
  <Resource
    name='Events'
    key='Events'
    list={(props) => <EventList clan={clan} {...props} />}
    show={EventShow}
    edit={(props) => <EventEdit clan={clan} {...props} />}
    create={(props) => <EventCreate clan={clan} {...props} />}
  />,
  <Resource
    name='Records'
    key='Records'
    options={{ label: "Time Categories" }}
    list={(props) => <RecordList clan={clan} {...props} />}
    show={RecordShow}
    edit={(props) => <RecordEdit clan={clan} {...props} />}
    create={(props) => <RecordCreate clan={clan} {...props} />}
  />,
  <Resource
    name='Times'
    key='Times'
    list={(props) => <TimeList clan={clan} {...props} />}
    show={TimeShow}
    edit={(props) => <TimeEdit clan={clan} {...props} />}
    create={(props) => <TimeCreate clan={clan} {...props} />}
  />,
  <Resource
    key='Mentors'
    name='Mentors'
    options={{ label: "Teaching Points" }}
    list={(props) => <MentorList clan={clan} {...props} />}
    show={MentorShow}
    edit={(props) => <MentorEdit clan={clan} {...props} />}
    create={(props) => <MentorCreate clan={clan} {...props} />}
  />,
  <Resource
    key='Scrapes'
    name='Scrapes'
    list={(props) => <ScrapeList clan={clan} {...props} />}
    show={ScrapeShow}
    edit={ScrapeEdit}
    create={(props) => <ScrapeCreate clan={clan} {...props} />}
  />,
  <Resource
    key='Badges'
    name='Badges'
    list={(props) => <BadgeList clan={clan} {...props} />}
    show={BadgeShow}
    edit={(props) => <BadgeEdit clan={clan} {...props} />}
    create={(props) => <BadgeCreate clan={clan} {...props} />}
  />,
  <Resource
    key='TaskCompleted'
    name='TaskCompleted'
    options={{ label: "Tasks Completed" }}
    list={(props) => <TaskCompletedList clan={clan} {...props} />}
    show={TaskCompletedShow}
    edit={(props) => <TaskCompletedEdit clan={clan} {...props} />}
    create={(props) => <TaskCompletedCreate clan={clan} {...props} />}
  />,
  <Resource
    key='Questions'
    name='Questions'
    options={{ label: "Application Questions" }}
    list={(props) => <QuestionList clan={clan} {...props} />}
    show={QuestionShow}
    edit={(props) => <QuestionEdit clan={clan} {...props} />}
    create={(props) => <QuestionCreate clan={clan} {...props} />}
  />,
  <Resource
    name='Ranks'
    key='Ranks'
    list={(props) => <RankList clan={clan} {...props} />}
    show={RankShow}
    edit={(props) => <RankEdit clan={clan} {...props} />}
    create={(props) => <RankCreate clan={clan} {...props} />}
  />,
  <Resource
    key='Responses'
    name='Responses'
    options={{ label: "Application Responses" }}
    list={(props) => <ResponseList clan={clan} {...props} />}
    show={ResponseShow}
    edit={(props) => <ResponseEdit clan={clan} {...props} />}
    create={(props) => <ResponseCreate clan={clan} {...props} />}
  />,
  <Resource
    key='Strikes'
    name='Strikes'
    list={(props) => <StrikeList clan={clan} {...props} />}
    show={StrikeShow}
    edit={(props) => <StrikeEdit clan={clan} {...props} />}
    create={(props) => <StrikeCreate clan={clan} {...props} />}
  />,
  <Resource
    key='Donations'
    name='Donations'
    list={(props) => <DonationList clan={clan} {...props} />}
    show={DonationShow}
    edit={(props) => <DonationEdit clan={clan} {...props} />}
    create={(props) => <DonationCreate clan={clan} {...props} />}
  />,
  <Resource
    key='Tasks'
    name='Tasks'
    list={(props) => <TaskList clan={clan} {...props} />}
    show={TaskShow}
    edit={(props) => <TaskEdit clan={clan} {...props} />}
    create={(props) => <TaskCreate clan={clan} {...props} />}
  />,
  <Resource
    key='Checklists'
    name='Checklists'
    list={(props) => <ChecklistList clan={clan} {...props} />}
    show={ChecklistShow}
    edit={(props) => <ChecklistEdit clan={clan} {...props} />}
    create={(props) => <ChecklistCreate clan={clan} {...props} />}
  />,
  <Resource
    name='items'
    key='items'
    list={(props) => <ItemList clan={clan} {...props} />}
    show={ItemShow}
    edit={(props) => <ItemEdit clan={clan} {...props} />}
    create={(props) => <ItemCreate clan={clan} {...props} />}
  />,
  <Resource
    name='bosses'
    key='bosses'
    list={(props) => <BossList clan={clan} {...props} />}
    show={BossShow}
    edit={(props) => <BossEdit clan={clan} {...props} />}
    create={(props) => <BossCreate clan={clan} {...props} />}
  />,
  <Resource
    key='PointCategories'
    name='PointCategories'
    options={{ label: "Point Categories" }}
    list={(props) => <PointCategoryList clan={clan} {...props} />}
    show={PointCategoryShow}
    edit={(props) => <PointCategoryEdit clan={clan} {...props} />}
    create={(props) => <PointCategoryCreate clan={clan} {...props} />}
  />,
  <Resource
    key='Points'
    name='Points'
    list={(props) => <PointList clan={clan} {...props} />}
    show={PointShow}
    edit={(props) => <PointEdit clan={clan} {...props} />}
    create={(props) => <PointCreate clan={clan} {...props} />}
  />,
  <Resource
    key='PointTransactions'
    name='PointTransactions'
    options={{ label: "Point Transactions" }}
    list={(props) => <PointTransactionList clan={clan} {...props} />}
    show={PointTransactionShow}
    edit={(props) => <PointTransactionEdit clan={clan} {...props} />}
    create={(props) => <PointTransactionCreate clan={clan} {...props} />}
  />,
  <Resource
    options={{ label: "Mentor Programs" }}
    key='MentorPrograms'
    name='MentorPrograms'
    list={(props) => <MentorProgramList clan={clan} {...props} />}
    show={MentorProgramShow}
    edit={(props) => <MentorProgramEdit clan={clan} {...props} />}
    create={(props) => <MentorProgramCreate clan={clan} {...props} />}
  />,
  <Resource
    key='Teachers'
    name='Teachers'
    list={(props) => <TeacherList clan={clan} {...props} />}
    show={TeacherShow}
    edit={(props) => <TeacherEdit clan={clan} {...props} />}
    create={(props) => <TeacherCreate clan={clan} {...props} />}
  />,
  <Resource
    key='Students'
    name='Students'
    list={(props) => <StudentList clan={clan} {...props} />}
    show={StudentShow}
    edit={(props) => <StudentEdit clan={clan} {...props} />}
    create={(props) => <StudentCreate clan={clan} {...props} />}
  />,
  <Resource
    key='Languages'
    name='Languages'
    list={(props) => <LanguageList clan={clan} {...props} />}
    show={LanguageShow}
    edit={LanguageEdit}
    create={(props) => <LanguageCreate clan={clan} {...props} />}
  />,
  <Resource
    key='Clans'
    name='Clans'
    list={(props) => <ClanList clan={clan} {...props} />}
    show={ClanShow}
    edit={ClanEdit}
  />,
  <Resource
    key='Discords'
    name='Discords'
    list={(props) => <DiscordList clan={clan} {...props} />}
    show={DiscordShow}
    edit={(props) => <DiscordEdit clan={clan} {...props} />}
    create={(props) => <DiscordCreate clan={clan} {...props} />}
  />,
  <Resource
    key='Audits'
    name='Audits'
    list={(props) => <AuditList clan={clan} {...props} />}
    show={AuditShow}
    edit={(props) => <AuditEdit clan={clan} {...props} />}
  />,
  <Resource
    name='Suggestions'
    key='Suggestions'
    list={(props) => <SuggestionList clan={clan} {...props} />}
    show={SuggestionShow}
    edit={(props) => <SuggestionEdit clan={clan} {...props} />}
    create={(props) => <SuggestionCreate clan={clan} {...props} />}
  />,
  <Resource name='EventMembers' key='EventMembers' />,
  <Resource
    name='BingoBoards'
    key='BingoBoards'
    list={(props) => <BingoBoardList clan={clan} {...props} />}
    show={BingoBoardShow}
    edit={(props) => <BingoBoardEdit clan={clan} {...props} />}
    create={(props) => <BingoBoardCreate clan={clan} {...props} />}
  />,
  <Resource
    name='BingoTeams'
    key='BingoTeams'
    list={(props) => <BingoTeamList clan={clan} {...props} />}
    show={BingoTeamShow}
    edit={(props) => <BingoTeamEdit clan={clan} {...props} />}
    create={(props) => <BingoTeamCreate clan={clan} {...props} />}
  />,
  <Resource
    name='BingoTiles'
    key='BingoTiles'
    list={(props) => <BingoTileList clan={clan} {...props} />}
    show={BingoTileShow}
    edit={(props) => <BingoTileEdit clan={clan} {...props} />}
    create={(props) => <BingoTileCreate clan={clan} {...props} />}
  />,
  <Resource
    name='BingoSubmissions'
    key='BingoSubmissions'
    list={(props) => <BingoSubmissionList clan={clan} {...props} />}
    show={BingoSubmissionShow}
    edit={(props) => <BingoSubmissionEdit clan={clan} {...props} />}
    create={(props) => <BingoSubmissionCreate clan={clan} {...props} />}
  />,
  <Resource
    name='BingoProgresses'
    key='BingoProgresses'
    list={(props) => <BingoProgressList clan={clan} {...props} />}
    show={BingoProgressShow}
    edit={(props) => <BingoProgressEdit clan={clan} {...props} />}
    create={(props) => <BingoProgressCreate clan={clan} {...props} />}
  />,
];

export default adminRoutes;
