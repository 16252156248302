import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import Layout from "../../components/Layout/Layout";
import Suggestions from "../../components/Settings/Suggestions";

const Settings: FunctionComponent = () => {
    const { t } = useTranslation();

    return (
        <Layout title={t("suggestions.pageTitle")}>
            <Suggestions />
        </Layout>
    );
};

export default Settings;
