import React from "react";
import Card from "@material-ui/core/Card";
import { CardContent } from "@material-ui/core";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { useTranslation } from 'react-i18next';
import ExpansionCard from "../../components/Shared/ExpansionCard"

export default function EventsBar({ events }) {
  const { t } = useTranslation();
  const datetime = (currentdate) =>
    `${currentdate.getDate()}/${currentdate.getMonth() + 1
    }/${currentdate.getFullYear()}`;

  return events.length > 0 ? (
    <Grid item xs={12}>
      <ExpansionCard title={t('lookup.eventsBar.title')}>
        <Grid container justify="center" spacing={3}>
          {events.map((group) => (
            <Grid key={group.group} item xs={12} sm={12} md={12}>
              <ExpansionPanel>
                <ExpansionPanelSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>{group.group}</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <Grid container justify="center" spacing={3}>
                    {group.data
                      .sort((a, b) => new Date(b.date) - new Date(a.date))
                      .map((event) => (
                        <Grid key={event.id} item sm={12}>
                          <Card>
                            <CardContent>
                              <Grid
                                container
                                justify="center"
                                alignItems="center"
                                spacing={3}
                              >
                                <Grid item xs={12} >
                                  <Typography>
                                    {datetime(new Date(event.date))} -{" "}
                                    {event.name}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </CardContent>
                          </Card>
                        </Grid>
                      ))}
                  </Grid>
                </ExpansionPanelDetails>
              </ExpansionPanel>
            </Grid>
          ))}
        </Grid>
      </ExpansionCard>
    </Grid>
  ) : (<> </>);
}
