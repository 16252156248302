import {
    List,
    Filter,
    DateField,
    DateInput,
    Pagination,
    Show,
    SimpleShowLayout,
    Datagrid,
    TextField,
    TextInput,
    ReferenceField,
    ReferenceInput,
    AutocompleteInput,
    Edit,
    SimpleForm,
} from "react-admin";
import React from "react";

const BigPagination = (props) => (
    <Pagination rowsPerPageOptions={[25, 50, 100, 2000]} {...props} />
);
const SearchFilter = (props) => (
    <Filter {...props}>
        <ReferenceInput
            label="Member"
            source="Member_id"
            reference="members"
            filter={{ Clan_id: props.clan.id }}
            alwaysOn
        >
            <AutocompleteInput optionText="username" />
        </ReferenceInput>
        <TextInput source="action" alwaysOn />
        <TextInput source="model_type" alwaysOn />
        <TextInput source="model_id" alwaysOn />
        <DateInput source="created_at" alwaysOn />
        <DateInput source="updated_at" alwaysOn />
    </Filter>
);
export const AuditList = (props) => (
    <List
        {...props}
        filters={<SearchFilter {...props} />}
        pagination={<BigPagination />}
        perPage={25}
        filter={{ Clan_id: props.clan.id }}
    >
        <Datagrid rowClick="show">
            <ReferenceField label="Member" source="Member_id" reference="members">
                <TextField source="username" />
            </ReferenceField>
            <TextField source="action" />
            <TextField source="model_type" />
            <TextField mulitine source="comment" />
            <DateField source="created_at" />
            <DateField source="updated_at" />
        </Datagrid>
    </List>
);

export const AuditShow = (props) => (
    <Show {...props}>
        <SimpleShowLayout>
            <ReferenceField label="Member" source="Member_id" reference="members">
                <TextField source="username" />
            </ReferenceField>
            <TextField source="action" />
            <TextField source="model_type" />
            <TextField source="model_id" />
            <TextField mulitine source="comment" />
            <DateField source="created_at" />
            <DateField source="updated_at" />
        </SimpleShowLayout>
    </Show>
);

export const AuditEdit = (props) => (
    <Edit {...props} undoable={false}>
        <SimpleForm>
            <ReferenceInput label="Member" source="Member_id" reference="members" filter={{ Clan_id: props.clan.id }} disabled>
                <AutocompleteInput source="username" />
            </ReferenceInput>
            <TextInput source="action" disabled />
            <TextInput source="model_type" disabled />
            <TextInput source="model_id" disabled />
            <TextInput mulitine source="comment" />
            <DateInput source="created_at" disabled />
            <DateInput source="updated_at" disabled />
        </SimpleForm>
    </Edit>
);
