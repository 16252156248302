import React from "react";
import {
  Filter,
  Pagination,
  List,
  Show,
  Create,
  Datagrid,
  TextField,
  TextInput,
  Edit,
  SimpleForm,
  ReferenceField,
  ReferenceInput,
  AutocompleteInput,
  NumberInput
} from "react-admin";

const BigPagination = (props) => (
  <Pagination rowsPerPageOptions={[25, 50, 100, 2000]} {...props} />
);
const SearchFilter = (props) => (
  <Filter {...props}>
    <TextInput source="value" alwaysOn />
    <ReferenceInput
      alwaysOn
      label="Member"
      source="Member_id"
      reference="members"
      filter={{ Clan_id: props.clan.id }}
    >
      <AutocompleteInput optionText="username" />
    </ReferenceInput>
    <ReferenceInput
      alwaysOn
      label="Category"
      source="PointCategory_id"
      reference="PointCategories"
      filter={{ Clan_id: props.clan.id }}
    >
      <AutocompleteInput optionText="name" />
    </ReferenceInput>
  </Filter>
);
export const PointList = (props) => (
  <List
    {...props}
    pagination={<BigPagination />}
    perPage={25}
    filters={<SearchFilter {...props} />}
    filter={{ Clan_id: props.clan.id }}
  >
    <Datagrid rowClick="show">
      <TextField source="value" />
      <ReferenceField label="Member" source="Member_id" reference="members">
        <TextField source="username" />
      </ReferenceField>
      <ReferenceField
        label="Category"
        source="PointCategory_id"
        reference="PointCategories"
      >
        <TextField source="name" />
      </ReferenceField>
    </Datagrid>
  </List>
);

export const PointShow = (props) => (
  <Show {...props}>
    <SimpleForm>
      <TextField source="value" />
      <ReferenceField label="Member" source="Member_id" reference="members">
        <TextField source="username" />
      </ReferenceField>
      <ReferenceField
        label="Category"
        source="PointCategory_id"
        reference="PointCategories"
      >
        <TextField source="name" />
      </ReferenceField>
    </SimpleForm>
  </Show>
);

export const PointEdit = (props) => (
  <Edit {...props} undoable={false}>
    <SimpleForm>
      <TextInput source="value" />
      <ReferenceInput label="Member" source="Member_id" reference="members" filter={{ Clan_id: props.clan.id }}>
        <AutocompleteInput optionText="username" />
      </ReferenceInput>
      <ReferenceInput
        label="Category"
        source="PointCategory_id"
        reference="PointCategories"
        filter={{ Clan_id: props.clan.id }}
      >
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
    </SimpleForm>
  </Edit>
);

export const PointCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="value" />
      <ReferenceInput label="Member" source="Member_id" reference="members" filter={{ Clan_id: props.clan.id }}>
        <AutocompleteInput optionText="username" />
      </ReferenceInput>
      <ReferenceInput
        label="Category"
        source="PointCategory_id"
        reference="PointCategories"
        filter={{ Clan_id: props.clan.id }}
      >
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
      <NumberInput source="Clan_id" initialValue={props.clan.id} disabled />
    </SimpleForm>
  </Create>
);
