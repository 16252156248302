import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Hidden from "@material-ui/core/Hidden";

function TabPanel({ children, value, index, ...other }) {
  return (
    <Typography
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </Typography>
  );
}

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  rootTab: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    maxHeight: "400px",
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
  tabPanel: {
    width: "100%",
    overflow: "auto",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
}));

export default function VerticalTabs(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div>
      <Hidden mdUp implementation="css">
        <div>
          <Tabs
            orientation="horizontal"
            variant="scrollable"
            value={value}
            onChange={handleChange}
            aria-label="Vertical tabs example"
            className={classes.tabs}
          >
            {props.tabs.map((tab, index) => (
              <Tab
                key={tab.label}
                label={tab.label}
                onClick={tab.onClick}
                {...a11yProps(index)}
              />
            ))}
          </Tabs>
          {props.tabs.map((tab, index) => (
            <TabPanel
              key={index}
              value={value}
              index={index}
              className={classes.tabPanel}
            >
              {tab.component}
            </TabPanel>
          ))}
        </div>
      </Hidden>
      <Hidden smDown implementation="css">
        <div className={classes.rootTab} style={props.headerStyle}>
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={value}
            onChange={handleChange}
            aria-label="Vertical tabs example"
            className={classes.tabs}
          >
            {props.tabs.map((tab, index) => (
              <Tab
                key={index}
                label={tab.label}
                onClick={tab.onClick}
                {...a11yProps(index)}
              />
            ))}
          </Tabs>
          {props.tabs.map((tab, index) => (
            <TabPanel
              key={index}
              value={value}
              index={index}
              className={classes.tabPanel}
            >
              {tab.component}
            </TabPanel>
          ))}
        </div>
      </Hidden>
    </div>
  );
}
