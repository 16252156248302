import React, { useContext, FunctionComponent, useEffect } from "react";
import { withRouter } from "react-router";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { useTranslation } from "react-i18next";
import EventCard from "../../components/Events/EventCard";
import Layout from "../../components/Layout/Layout";
import { ClanContext } from "../../contexts/clanContext";
import { SubmissionContext } from "../../contexts/submissionContext";

const Events: FunctionComponent = () => {
  const { clan } = useContext(ClanContext);
  const { events, futureEvents, eventsFetched, fetchEvents } = useContext(SubmissionContext);
  const { t } = useTranslation();

  useEffect(() => {
    if (clan && clan.id && !eventsFetched) {
      fetchEvents();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventsFetched]);

  return (
    <Layout loading={!eventsFetched} title={t("events.pageTitle")}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={12}>
          <Typography variant="h6" gutterBottom>
            {t("events.upcoming")}
          </Typography>
        </Grid>
        {futureEvents
          .map((event) => (
            <Grid key={event.id} item xs={12} sm={12} md={12}>
              <EventCard event={event} />
            </Grid>
          ))}
        <Grid item xs={12} sm={12} md={12}>
          <Typography variant="h6" gutterBottom>
            {t("events.last30")}
          </Typography>
        </Grid>
        {events
          .map((event) => (
            <Grid key={event.id} item xs={12} sm={12} md={12}>
              <EventCard event={event} />
            </Grid>
          ))}
      </Grid>
    </Layout>
  );
};

export default withRouter(Events);
