import React, { useContext, FunctionComponent, useEffect } from "react";
import Button from "@material-ui/core/Button";
import { withRouter } from "react-router";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import { useTranslation } from "react-i18next";
import Typography from "@material-ui/core/Typography";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CenteredGrid from "../../components/Shared/CenteredGrid";
import Layout from "../../components/Layout/Layout";
import { UserContext } from "../../contexts/userContext";
import { ClanContext } from "../../contexts/clanContext";
import { SubmissionContext } from "../../contexts/submissionContext";
import { FormGroup } from "@material-ui/core";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
interface Application {
  username?: string;
  timezone?: string;
  Clan_id: number;
  ironman?: boolean;
  hardcoreIronman?: boolean;
  ultimateIronman?: boolean;
  normal?: boolean;
  [key: string]: any;
}

const RegisterPage: FunctionComponent = () => {
  const { t } = useTranslation();
  const { apply, submitted } = useContext(UserContext);
  const { clan } = useContext(ClanContext);
  const { questions, timezoneChoices, questionsFetched, fetchQuestions } = useContext(SubmissionContext);
  const [application, setApplication] = React.useState({ Clan_id: clan.id } as Application);

  useEffect(() => {
    if (clan && clan.id && !questionsFetched) {
      fetchQuestions();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionsFetched]);

  return (
    <Layout
      loading={!questionsFetched}
      title={t("apply.pageTitle")}
    >
      {!submitted ? (
        <CenteredGrid container spacing={1}>
          <>
            <Grid item xs={12}>
              <Typography>{t("apply.helperText")}</Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="1."
                placeholder={t("apply.usernamePlaceholder")}
                fullWidth
                value={application.username}
                type="text"
                onChange={(e) =>
                  setApplication({ ...application, username: e.target.value })
                }
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                options={timezoneChoices}
                autoHighlight
                autoSelect
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    label="2."
                    placeholder={t("apply.timezoneText")}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                )}
                onChange={(event, timezone) => {
                  setApplication({ ...application, timezone: timezone || "" });
                }}
              />
            </Grid>
            {(clan.ironman || clan.hardcoreIronman || clan.ultimateIronman || clan.normal) && (
              <Grid item xs={12} alignContent="flex-start" style={{ display: "flex" }}>
                <FormControl component="fieldset">
                  <FormGroup aria-label="position" row>

                    <FormLabel component="legend">3.Type</FormLabel>
                    <RadioGroup style={{ flexDirection: "row" }} onChange={(event) => {
                      setApplication({ ...application, normal: false, ironman: false, hardcoreIronman: false, ultimateIronman: false, [event.target.value]: true });
                    }}>
                      {clan.normal && (<FormControlLabel labelPlacement="start" value="normal" control={<Radio />} label="Normal" />
                      )}
                      {clan.ironman && (<FormControlLabel labelPlacement="start" value="ironman" control={<Radio />} label="Ironman" />
                      )}
                      {clan.hardcoreIronman && (<FormControlLabel labelPlacement="start" value="hardcoreIronman" control={<Radio />} label="Hardcore Ironman" />
                      )}
                      {clan.ultimateIronman && (<FormControlLabel labelPlacement="start" value="ultimateIronman" control={<Radio />} label="Ultimate Ironman" />
                      )}
                    </RadioGroup>
                  </FormGroup>
                </FormControl>
              </Grid>
            )}

            {questions.map((question, index) => (
              <Grid item xs={12} key={question.id}>
                <TextField
                  label={`${index + ((clan.ironman || clan.hardcoreIronman || clan.ultimateIronman || clan.normal) ? 4 : 3)}.`}
                  placeholder={question.question}
                  fullWidth
                  onChange={(e) =>
                    setApplication({
                      ...application,
                      [question.id]: e.target.value,
                    })
                  }
                  type="text"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
            ))}
            <Grid item xs={12}>
              <Button
                variant="outlined"
                onClick={() => apply(application)}
                disabled={
                  Object.keys(application).length !== questions.length + ((clan.ironman || clan.hardcoreIronman || clan.ultimateIronman || clan.normal) ? 7 : 3)
                }
              >
                {t("apply.submitLabel")}
              </Button>
            </Grid>
          </>
        </CenteredGrid>
      ) : (
          <CenteredGrid container spacing={3}>
            <>
              <Grid item xs={12}>
                <Typography variant="caption">{t("apply.success")}</Typography>
              </Grid>
            </>
          </CenteredGrid>
        )}
    </Layout>
  );
};

export default withRouter(RegisterPage);
