import React from 'react';
import {
  List,
  Show,
  Pagination,
  Create,
  SimpleShowLayout,
  Datagrid,
  TextField,
  TextInput,
  Edit,
  SimpleForm,
  NumberInput,
} from 'react-admin';

const BigPagination = (props) => (
  <Pagination rowsPerPageOptions={[25, 50, 100, 2000]} {...props} />
);

export const DiscordList = (props) => (
  <List
    {...props}
    pagination={<BigPagination />}
    perPage={25}
    filter={{ Clan_id: props.clan.id }}
  >
    <Datagrid rowClick='show'>
      <TextField source='serverId' />
      <TextField source='unapprovedDropsId' />
      <TextField source='dropStatusId' />
      <TextField source='unapprovedTimesId' />
      <TextField source='timeStatusId' />
      <TextField source='unapprovedTasksCompletedId' />
      <TextField source='tasksCompletedStatusId' />
      <TextField source='unapprovedMentorsId' />
      <TextField source='mentorStatusId' />
      <TextField source='unapprovedMembersId' />
      <TextField source='memberStatusId' />
      <TextField source='adminMemberStatusId' />
      <TextField source='strikeStatusId' />
      <TextField source='unapprovedStatusesId' />
      <TextField source='applyId' />
      <TextField source='eventsChannelId' />
      <TextField source='memberRankUpdateId' />
      <TextField source='trialMessage' />
    </Datagrid>
  </List>
);

export const DiscordShow = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source='serverId' />
      <TextField source='unapprovedDropsId' />
      <TextField source='dropStatusId' />
      <TextField source='unapprovedTimesId' />
      <TextField source='timeStatusId' />
      <TextField source='unapprovedTasksCompletedId' />
      <TextField source='tasksCompletedStatusId' />
      <TextField source='unapprovedMentorsId' />
      <TextField source='mentorStatusId' />
      <TextField source='unapprovedMembersId' />
      <TextField source='memberStatusId' />
      <TextField source='adminMemberStatusId' />
      <TextField source='strikeStatusId' />
      <TextField source='unapprovedStatusesId' />
      <TextField source='applyId' />
      <TextField source='eventsChannelId' />
      <TextField source='memberRankUpdateId' />
      <TextField source='trialMessage' />
    </SimpleShowLayout>
  </Show>
);

export const DiscordEdit = (props) => (
  <Edit {...props} undoable={false}>
    <SimpleForm>
      <TextInput source='serverId' />
      <TextInput source='unapprovedDropsId' />
      <TextInput source='dropStatusId' />
      <TextInput source='unapprovedTimesId' />
      <TextInput source='timeStatusId' />
      <TextInput source='unapprovedTasksCompletedId' />
      <TextInput source='tasksCompletedStatusId' />
      <TextInput source='unapprovedMentorsId' />
      <TextInput source='mentorStatusId' />
      <TextInput source='unapprovedMembersId' />
      <TextInput source='memberStatusId' />
      <TextInput source='adminMemberStatusId' />
      <TextInput source='strikeStatusId' />
      <TextInput source='unapprovedStatusesId' />
      <TextInput source='applyId' />
      <TextInput source='eventsChannelId' />
      <TextInput source='memberRankUpdateId' />
      <TextInput source='trialMessage' multiline />
    </SimpleForm>
  </Edit>
);

export const DiscordCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source='serverId' />
      <TextInput source='unapprovedDropsId' />
      <TextInput source='dropStatusId' />
      <TextInput source='unapprovedTimesId' />
      <TextInput source='timeStatusId' />
      <TextInput source='unapprovedTasksCompletedId' />
      <TextInput source='tasksCompletedStatusId' />
      <TextInput source='unapprovedMentorsId' />
      <TextInput source='mentorStatusId' />
      <TextInput source='unapprovedMembersId' />
      <TextInput source='memberStatusId' />
      <TextInput source='adminMemberStatusId' />
      <TextInput source='strikeStatusId' />
      <TextInput source='unapprovedStatusesId' />
      <TextInput source='applyId' />
      <TextInput source='eventsChannelId' />
      <TextInput source='memberRankUpdateId' />
      <TextInput source='trialMessage' multiline />
      <NumberInput source='Clan_id' initialValue={props.clan.id} disabled />
    </SimpleForm>
  </Create>
);
