import React from "react";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import Lottie from "react-lottie";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { CardContent } from "@material-ui/core";
import Cookies from "universal-cookie";
import { useTranslation } from 'react-i18next';
import pepeData from "../Loading/loading.json";
import hugData from "../Loading/hugging.json";
import winkData from "../Loading/wink.json";
import teaData from "../Loading/tea.json";
import headData from "../Loading/head.json";
import spiderData from "../Loading/spider.json";

export default function LoadingIndicators() {
  const { t } = useTranslation();
  const cookies = new Cookies();
  const toggleLoadingIndicator = (type) => {
    if (cookies.get("indicator")) {
      cookies.remove("indicator", { path: "/" });
    }
    cookies.set("indicator", type, {
      path: "/",
      expires: new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
    });
  };

  return (
    <Card>
      <CardContent>
        <Grid container justify="center" spacing={3}>
          <Grid item xs={12}>
            <Typography>
              {t('settings.loadingIndicators.title')}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={4} justify="center">
            <Lottie
              options={{
                loop: true,
                autoplay: true,
                animationData: pepeData,
                rendererSettings: {
                  preserveAspectRatio: "xMidYMid slice",
                },
              }}
              height={200}
              width={200}
              isStopped={false}
              isPaused={false}
            />
            <Button
              variant="outlined"
              onClick={() => {
                toggleLoadingIndicator("pepe");
              }}
            >
              {t('settings.loadingIndicators.classic')}
            </Button>
          </Grid>
          <Grid item xs={12} sm={6} md={4} justify="center">
            <Lottie
              options={{
                loop: true,
                autoplay: true,
                animationData: hugData,
                rendererSettings: {
                  preserveAspectRatio: "xMidYMid slice",
                },
              }}
              height={200}
              width={200}
              isStopped={false}
              isPaused={false}
            />
            <Button
              variant="outlined"
              onClick={() => {
                toggleLoadingIndicator("hug");
              }}
            >
              {t('settings.loadingIndicators.hug')}
            </Button>
          </Grid>
          <Grid item xs={12} sm={6} md={4} justify="center">
            <Lottie
              options={{
                loop: true,
                autoplay: true,
                animationData: winkData,
                rendererSettings: {
                  preserveAspectRatio: "xMidYMid slice",
                },
              }}
              height={200}
              width={200}
              isStopped={false}
              isPaused={false}
            />
            <Button
              variant="outlined"
              onClick={() => {
                toggleLoadingIndicator("wink");
              }}
            >
              {t('settings.loadingIndicators.wink')}
            </Button>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Lottie
              options={{
                loop: true,
                autoplay: true,
                animationData: teaData,
                rendererSettings: {
                  preserveAspectRatio: "xMidYMid slice",
                },
              }}
              height={200}
              width={200}
              isStopped={false}
              isPaused={false}
            />
            <Button
              variant="outlined"
              onClick={() => {
                toggleLoadingIndicator("tea");
              }}
            >
              {t('settings.loadingIndicators.tea')}
            </Button>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Lottie
              options={{
                loop: true,
                autoplay: true,
                animationData: headData,
                rendererSettings: {
                  preserveAspectRatio: "xMidYMid slice",
                },
              }}
              height={200}
              width={200}
              isStopped={false}
              isPaused={false}
            />
            <Button
              variant="outlined"
              onClick={() => {
                toggleLoadingIndicator("head");
              }}
            >
              {t('settings.loadingIndicators.head')}
            </Button>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Lottie
              options={{
                loop: true,
                autoplay: true,
                animationData: spiderData,
                rendererSettings: {
                  preserveAspectRatio: "xMidYMid slice",
                },
              }}
              height={200}
              width={200}
              isStopped={false}
              isPaused={false}
            />
            <Button
              variant="outlined"
              onClick={() => {
                toggleLoadingIndicator("spider");
              }}
            >
              {t('settings.loadingIndicators.spider')}
            </Button>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
