import React, { useContext, useEffect, FunctionComponent } from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import EventAvailableIcon from "@material-ui/icons/EventAvailable";
import PeopleIcon from "@material-ui/icons/People";
import LocalLibraryIcon from "@material-ui/icons/LocalLibrary";
import Typography from "@material-ui/core/Typography";
import { useTranslation } from "react-i18next";
import InfoArea from "../../components/Home/InfoArea";
import Parallax from "../../components/Home/Parallax";
import { ClanContext } from "../../contexts/clanContext";
import { ThemeContext } from "../../contexts/themeContext";
import { SubmissionContext } from "../../contexts/submissionContext";

const useStyles = makeStyles({
  dark: {
    background: "#333",
  },
  white: {
    background: "#fff",
  },
  main: {
    position: "relative",
    margin: "-60px 30px 0px",
    borderRadius: "6px",
    boxShadow:
      "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
  },
  section: {
    padding: "70px 0",
    textAlign: "center",
  },
  homeRoot: {
    paddingBottom: "40px",
  },
});

const Home: FunctionComponent = () => {
  const { clan, clanFetched } = useContext(ClanContext);
  const { stats, fetchStats, statsFetched, } = useContext(SubmissionContext);
  const { setLoading, mode } = useContext(ThemeContext);
  const baseUrl =
    !process.env.REACT_APP_STANDALONE_ID && clan?.id ? `/${clan.slug}` : "";
  const { t } = useTranslation();
  const classes = useStyles();
  const numberWithCommas = (x: number) =>
    (x || 0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  useEffect(() => {
    if (clan && clan.id && !statsFetched) {
      fetchStats();
    }
    setLoading(!statsFetched && !clanFetched);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statsFetched, clanFetched]);

  return (statsFetched && clanFetched) ? (
    <div className={`${classes.homeRoot} ${classes[mode]}`}>
      <Parallax image={clan.clanHeaderImage} />
      <Paper className={`${classes.main}`}>
        <Grid item md={12}>
          <Paper>
            <div className={classes.section}>
              <Grid container justify="center" spacing={6}>
                <Grid item xs={11} sm={11} md={12}>
                  <Typography variant="h3">{t("home.title")}</Typography>
                </Grid>
                <Grid item xs={11} sm={11} md={12}>
                  <Typography variant="h5">{t("home.subtitle")}</Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={4}>
                  <InfoArea
                    title={`${numberWithCommas(
                      stats.totalLootValue / 1000
                    )} ${t("home.infoBox1.title")}`}
                    description={t("home.infoBox1.description")}
                    Icon={EventAvailableIcon}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4}>
                  <InfoArea
                    title={`${stats.activeMembers} ${t(
                      "home.infoBox2.title"
                    )}`}
                    description={t("home.infoBox2.description")}
                    Icon={PeopleIcon}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4}>
                  <InfoArea
                    title={`${numberWithCommas(stats.totalRaidsEHB)} ${t(
                      "home.infoBox3.title"
                    )}`}
                    description={t("home.infoBox3.description")}
                    Icon={LocalLibraryIcon}
                  />
                </Grid>
                <Grid item xs={10} sm={10} md={4}>
                  <Link to={`${baseUrl}/requirements`}>
                    <Button variant="outlined">
                      {t("home.requirementsLink")}
                    </Button>
                  </Link>
                </Grid>
                <Grid item xs={10} sm={10} md={4}>
                  <Link to={`${baseUrl}/apply`}>
                    <Button variant="outlined">
                      {t("home.applyLink")}
                    </Button>
                  </Link>
                </Grid>
              </Grid>
            </div>
          </Paper>
        </Grid>
      </Paper>
    </div>
  ) : (
    <div />
  );
};

export default Home;
