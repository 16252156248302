import React, { useEffect, useState, useContext } from "react";
import { Card, CardContent, Typography, Box } from "@mui/material";
import { Link } from "react-router-dom";
import StaffGraph from "./StaffGraph";
import { ClanContext } from "../../contexts/clanContext";
import { SubmissionContext } from "../../contexts/submissionContext";

const Dashboard = ({ user }) => {
  const { clan } = useContext(ClanContext);
  const { fetchStarsStats } = useContext(SubmissionContext);
  const [starStats, setStarStats] = useState(undefined);

  useEffect(() => {
    const loadStarStats = async () => setStarStats(await fetchStarsStats());
    if (starStats === undefined) {
      loadStarStats();
    }
  }, [starStats, fetchStarsStats]);

  return (
    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 2, padding: 2 }}>
      {[
        {
          title: `Welcome to ${clan.name} Admin!`,
          content: "Manage the clan efficiently using the tools below.",
        },
        {
          title: "Member Management",
          content: (
            <>
              To accept a new <Link to="/members">member</Link>:
              <br />- Monitor Discord #unapproved-apps for new posts
              <br />- Check requirements and validate screenshots
              <br />- Approve, update database, and welcome the new member
            </>
          ),
        },
        {
          title: "Drop Approvals",
          content: (
            <>
              To approve a <Link to="/drops">drop</Link>:
              <br />- Locate the item
              <br />- Verify accuracy of details and screenshot
              <br />- Approve or reject
            </>
          ),
        },
        {
          title: "Name Changes",
          content: (
            <>
              - Monitor Discord #name-changes
              <br />- Update the member’s profile with their new username
              <br />- Confirm changes and react to the Discord post
            </>
          ),
        },
        {
          title: "Unacceptable Behavior",
          content: (
            <>
              - Avoid fueling arguments, resolve issues calmly
              <br />- Address conflicts privately, not in cc
              <br />- Abuse of authority is not tolerated
              <br />- Issue informal warnings first, but escalate when needed
            </>
          ),
        },
        {
          title: "Staff Performance",
          content: <StaffGraph data={starStats} />,
        },
      ]
        .filter(Boolean)
        .map((card, index) => (
          <Card
            key={index}
            sx={{
              width: { xs: "100%", md: "calc(50% - 16px)" },
              background: "white",
              padding: 2,
              borderRadius: 2,
              boxShadow: 1,
              minHeight: 200,
            }}
          >
            <CardContent>
              <Typography variant="h6" sx={{ mb: 1 }}>
                {card.title}
              </Typography>
              <Typography>{card.content}</Typography>
            </CardContent>
          </Card>
        ))}
    </Box>
  );
};

export default Dashboard;
