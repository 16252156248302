import React, { FunctionComponent } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles({
    textCenter: {
        textAlign: 'center',
    },
});

interface CenteredGridProps {
    container?: boolean;
    item?: boolean;
    spacing?: 0 | 3 | 1 | 2 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | undefined;
    children: JSX.Element
}

const CenteredGrid: FunctionComponent<CenteredGridProps> = ({ container, item, spacing = 3, children }) => {
    const classes = useStyles();

    return (
        <Grid container={container} item={item} className={classes.textCenter} spacing={spacing}>
            {children}
        </Grid>
    );
}

export default CenteredGrid
