import React, { useContext } from "react";
import ListItem from "./ListItem";
import List from "@material-ui/core/List";
import { useTranslation } from "react-i18next";
import ForumIcon from "@material-ui/icons/Forum";
import TwitterIcon from "@material-ui/icons/Twitter";
import FavoriteIcon from "@material-ui/icons/Favorite";
import { ClanContext } from "../../contexts/clanContext";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import ListItemDefault from "@material-ui/core/ListItem";

export default function Social({ baseUrl }) {
  const { clan } = useContext(ClanContext);
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(!open);
  };
  const { t } = useTranslation();

  return (
    <List>
      <ListItemDefault button dense disableGutters onClick={handleClick}>
        <ListItemText primary={t("header.sidebar.social")} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemDefault>
      <Collapse in={open} timeout='auto' unmountOnExit>
        <List component='div' disablePadding>
          {clan.forumUrl && (
            <ListItem
              to={{ pathname: clan.forumUrl }}
              target='_blank'
              text={t("header.sidebar.forums")}
              Icon={ForumIcon}
            />
          )}
          {clan.twitterUrl && (
            <ListItem
              to={{ pathname: clan.twitterUrl }}
              target='_blank'
              text={t("header.sidebar.twitter")}
              Icon={TwitterIcon}
            />
          )}
          {clan.discordUrl && (
            <ListItem
              to={{ pathname: clan.discordUrl }}
              target='_blank'
              text={t("header.sidebar.discord")}
              Icon={() => (
                <i
                  className={`fab fa-discord`}
                  style={{ paddingLeft: "3px", 
                    position: "relative",
                    fontSize: "20px !important",
                    marginRight: "4px",
                   }}
                />
              )}
            />
          )}
          {clan.id && (
            <ListItem
              to={`${baseUrl}/donate`}
              text={t("header.sidebar.donate")}
              Icon={FavoriteIcon}
            />
          )}
        </List>
      </Collapse>
    </List>
  );
}
