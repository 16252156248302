import React, { useContext, FunctionComponent, useEffect } from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TextField from "@material-ui/core/TextField";
import { useTranslation } from "react-i18next";
import Layout from "../../components/Layout/Layout";
import { UserContext } from "../../contexts/userContext";
import { ChecklistContext } from "../../contexts/checklistContext";
import { ClanContext } from "../../contexts/clanContext";
import { Checklist, Task } from "../../models";
import { SubmissionContext } from "../../contexts/submissionContext";

const Pets: FunctionComponent = () => {
  const { memberTasks, createTask, fetchTasksCompleted } = useContext(ChecklistContext);
  const { clan } = useContext(ClanContext);
  const { checklists, fetchChecklists, checklistsFetched } = useContext(SubmissionContext);
  const pets = checklists
    .filter((checklist) => checklist.type === "pet")
    .sort((a: Checklist, b: Checklist) =>
      (a.name || "") > (b.name || "") ? 1 : -1
    );
  const { user } = useContext(UserContext);
  const { t } = useTranslation();

  const submitTask = async (
    e: React.KeyboardEvent<HTMLInputElement>,
    task: Task
  ) => {
    if (e.key === "Enter") {
      await createTask({
        Task_id: task.id,
        Member_id: user.id,
        Clan_id: user.Clan_id,
        date: new Date(),
        screenshot: (e.target as HTMLInputElement).value,
      });
      fetchTasksCompleted();
    }
  };

  useEffect(() => {
    if (clan && clan.id && !checklistsFetched) {
      fetchChecklists();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checklistsFetched]);

  return (
    <Layout loading={!checklistsFetched} title={t("pets.pageTitle")}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={12}>
          <Typography variant="h6" gutterBottom>
            {t("pets.incomplete")}
          </Typography>
        </Grid>
        <Grid container spacing={2}>
          {pets
            .filter(
              (list) =>
                list &&
                list.tasks &&
                list.tasks
                  .map((t) => t.id)
                  .filter(
                    (val) =>
                      [memberTasks.map((t) => !t.approved && t.Task_id)]
                        .flat()
                        .indexOf(val) === -1
                  ).length > 0
            )
            .map((checklist: Checklist) => (
              <Grid key={checklist.id} item xs={12} sm={12} md={12}>
                <ExpansionPanel>
                  <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography>{checklist.name}</Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <Grid container justify="center">
                      <Grid item xs={12} sm={12} md={12}>
                        <Typography>{checklist.description}</Typography>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12}>
                        <Table
                          aria-label="Task list"
                          style={{
                            width: "100%",
                          }}
                        >
                          <TableHead>
                            <TableRow>
                              <TableCell>{t("pets.nameHeader")}</TableCell>
                              <TableCell align="right">
                                {t("pets.screenshotHeader")}
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {checklist &&
                              checklist.tasks &&
                              checklist.tasks
                                .sort((a: Task, b: Task) =>
                                  (a.description || "") > (b.description || "")
                                    ? -1
                                    : 1
                                )
                                .map(
                                  (task: Task) =>
                                    !memberTasks.find(
                                      (t) => t.Task_id === task.id
                                    ) && (
                                      <TableRow key={task.name}>
                                        <TableCell component="th" scope="row">
                                          {task.name}
                                        </TableCell>
                                        <TableCell align="right">
                                          <TextField
                                            id="skills"
                                            label={t("pets.submitLabel")}
                                            fullWidth
                                            onKeyDown={(
                                              e: React.KeyboardEvent<any>
                                            ) => submitTask(e, task)}
                                            type="text"
                                            helperText={t("pets.helperText")}
                                          />
                                        </TableCell>
                                      </TableRow>
                                    )
                                )}
                          </TableBody>
                        </Table>
                      </Grid>
                    </Grid>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              </Grid>
            ))}
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <Typography variant="h6" gutterBottom>
            {t("pets.complete")}
          </Typography>
        </Grid>
        <Grid container spacing={2}>
          {pets
            .filter(
              (list: Checklist) =>
                list &&
                list.tasks &&
                list.tasks
                  .map((t: Task) => t.id)
                  .filter(
                    (val) =>
                      [memberTasks.map((t) => t.approved && t.Task_id)]
                        .flat()
                        .indexOf(val) !== -1
                  ).length > 0
            )
            .map((checklist) => (
              <Grid key={checklist.id} item xs={12} sm={12} md={12}>
                <ExpansionPanel>
                  <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography>{checklist.name}</Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <Grid container justify="center">
                      <Grid item xs={12} sm={12} md={12}>
                        <Typography>{checklist.description}</Typography>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12}>
                        <Table
                          aria-label="Task list"
                          style={{
                            width: "100%",
                          }}
                        >
                          <TableHead>
                            <TableRow>
                              <TableCell>{t("pets.nameHeader")}</TableCell>
                              <TableCell align="right">
                                {t("pets.screenshotHeader")}
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {checklist &&
                              checklist.tasks &&
                              checklist.tasks
                                .sort((a: Task, b: Task) =>
                                  (a.description || "") > (b.description || "")
                                    ? -1
                                    : 1
                                )
                                .map(
                                  (task) =>
                                    memberTasks.find(
                                      (t) => t.Task_id === task.id
                                    ) && (
                                      <TableRow key={task.name}>
                                        <TableCell component="th" scope="row">
                                          {task.name}
                                        </TableCell>
                                        <TableCell align="right">
                                          <TextField
                                            id="skills"
                                            label={t("pets.incomplete")}
                                            fullWidth
                                            value="Complete"
                                            disabled
                                            type="text"
                                          />
                                        </TableCell>
                                      </TableRow>
                                    )
                                )}
                          </TableBody>
                        </Table>
                      </Grid>
                    </Grid>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              </Grid>
            ))}
        </Grid>
      </Grid>
    </Layout>
  );
};

export default Pets;
