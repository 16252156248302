import React, { useContext } from "react";
import Grid from "@material-ui/core/Grid";
import { useTranslation } from "react-i18next";
import CollectionLogTabs from "../../components/Lookup/CollectionLogTabs";
import CollectionLog from "../../components/Lookup/CollectionLog";
import ExpansionCard from "../../components/Shared/ExpansionCard";
import { SubmissionContext } from "../../contexts/submissionContext";

const CollectionLogCard = ({ drops, tanks }) => {
  const { t } = useTranslation();
  const { bossesWithDrops } = useContext(SubmissionContext);

  let tabs = [];
  if (bossesWithDrops) {
    tabs = bossesWithDrops
      .map((boss) => ({
        label: boss.name,
        component: <CollectionLog boss={boss} drops={drops} tanks={tanks} />,
      }));
    tabs.unshift({
      label: "Overall",
      component: (
        <CollectionLog
          bosses={bossesWithDrops}
          drops={drops}
          tanks={tanks}
        />
      ),
    });
  }
  return (
    <ExpansionCard title={t("lookup.collectionLogCard.title")}>
      <Grid container>
        <Grid item xs={12}>
          <CollectionLogTabs tabs={tabs} />
        </Grid>
      </Grid>
    </ExpansionCard>
  );
};

export default CollectionLogCard;
