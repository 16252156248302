import React from "react";
import BadgeBar from "./BadgeBar"
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

const DiaryBadgeBar = ({ memberBadges }) => {
  const { t } = useTranslation();
  let diaryBadges = _.uniqBy(memberBadges, v => v.Badge_id).filter((task) => task.badge && task.badge.name.includes("-"))
  diaryBadges = diaryBadges.filter(badgeRecord => !diaryBadges.find(x => badgeRecord.badge.name.split("-")[0] === x.badge.name.split("-")[0] &&
    parseInt(x.badge.name.split("-")[1]) > parseInt(badgeRecord.badge.name.split("-")[1]))).map(x => x.badge)

    return (
    <BadgeBar
      title={t('lookup.diaryBadgeBar.title')}
      badges={diaryBadges}
    />
  );
}

export default DiaryBadgeBar
