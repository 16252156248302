import React, { Fragment, useState } from "react";
import {
  List,
  useUpdate,
  TopToolbar,
  Filter,
  TabbedShowLayout,
  Tab,
  SimpleForm,
  Button,
  Confirm,
  useUpdateMany,
  useRefresh,
  useNotify,
  useUnselectAll,
  Pagination,
  BulkDeleteButton,
  UrlField,
  Show,
  Create,
  EditButton,
  Datagrid,
  BooleanField,
  TextField,
  TextInput,
  ReferenceField,
  BooleanInput,
  ReferenceInput,
  AutocompleteInput,
  Edit,
  NumberInput,
  DateInput,
  DateField,
} from "react-admin";

const BigPagination = (props) => (
  <Pagination rowsPerPageOptions={[25, 50, 100, 2000]} {...props} />
);
const SearchFilter = (props) => (
  <Filter {...props}>
    <ReferenceInput
      label='Task'
      source='Task_id'
      reference='Tasks'
      alwaysOn
      filter={{ Clan_id: props.clan.id }}
    >
      <AutocompleteInput optionText='name' />
    </ReferenceInput>
    <ReferenceInput
      label='Member'
      source='Member_id'
      reference='members'
      filter={{ Clan_id: props.clan.id }}
      alwaysOn
    >
      <AutocompleteInput optionText='username' />
    </ReferenceInput>
    <ReferenceInput
      label='Admin'
      source='admin'
      reference='members'
      alwaysOn
      filter={{ Clan_id: props.clan.id }}
    >
      <AutocompleteInput optionText='username' />
    </ReferenceInput>
    <DateInput source='date' alwaysOn />
  </Filter>
);
const ApproveTasks = ({ selectedIds, resource }) => {
  const [open, setOpen] = useState(false);
  const refresh = useRefresh();
  const notify = useNotify();
  const unselectAll = useUnselectAll();
  const [updateMany, { loading }] = useUpdateMany(
    "taskCompleted",
    selectedIds,
    { approved: true },
    {
      onSuccess: () => {
        refresh();
        notify("Tasks approved!");
        unselectAll(resource);
      },
      onFailure: () => notify("Error: Tasks not approved", "warning"),
    }
  );
  const handleClick = () => setOpen(true);
  const handleDialogClose = () => setOpen(false);

  const handleConfirm = () => {
    updateMany();
    setOpen(false);
  };
  return (
    <>
      <Button label='Approve Tasks' onClick={handleClick} />
      <Confirm
        isOpen={open}
        loading={loading}
        title='Approve Tasks'
        content='Are you sure you want to approve these tasks?'
        onConfirm={handleConfirm}
        onClose={handleDialogClose}
      />
    </>
  );
};

const DenyTasks = ({ selectedIds, resource }) => {
  const [open, setOpen] = useState(false);
  const refresh = useRefresh();
  const notify = useNotify();
  const unselectAll = useUnselectAll();
  const [updateMany, { loading }] = useUpdateMany(
    "taskCompleted",
    selectedIds,
    { approved: false },
    {
      onSuccess: () => {
        refresh();
        notify("Tasks denied!");
        unselectAll(resource);
      },
      onFailure: () => notify("Error: Tasks not denied", "warning"),
    }
  );
  const handleClick = () => setOpen(true);
  const handleDialogClose = () => setOpen(false);

  const handleConfirm = () => {
    updateMany();
    setOpen(false);
  };

  return (
    <>
      <Button label='Deny' onClick={handleClick} />
      <Confirm
        isOpen={open}
        loading={loading}
        title='Deny Tasks'
        content='Are you sure you want to deny these Tasks?'
        onConfirm={handleConfirm}
        onClose={handleDialogClose}
      />
    </>
  );
};

const ApproveButton = ({ record }) => {
  const refresh = useRefresh();
  const notify = useNotify();
  const [approve, { loading }] = useUpdate(
    "taskCompleted",
    record ? record.id : null,
    { approved: true },
    {
      onSuccess: () => {
        console.log("responded");
        refresh();
        notify("Task approved!");
      },
      onFailure: (error) => console.log(error.message),
    }
  );
  return <Button label='Approve' onClick={approve} disabled={loading} />;
};

const DenyButton = ({ record }) => {
  const refresh = useRefresh();
  const notify = useNotify();
  const [Deny, { loading }] = useUpdate(
    "taskCompleted",
    record ? record.id : null,
    { approved: false },
    {
      onSuccess: () => {
        refresh();
        notify("Task denied!");
      },
      onFailure: (error) => notify(`Error: ${error.message}`, "warning"),
    }
  );
  return <Button label='Deny' onClick={Deny} disabled={loading} />;
};

const PostBulkActionButtons = (props) => (
  <>
    <ApproveTasks label='Approve Tasks' {...props} />
    <DenyTasks label='Deny Tasks' {...props} />
    {/* default bulk delete action */}
    <BulkDeleteButton {...props} />
  </>
);

export const TaskCompletedList = (props) => (
  <List
    {...props}
    pagination={<BigPagination />}
    perPage={25}
    bulkActionButtons={<PostBulkActionButtons {...props} />}
    filters={<SearchFilter {...props} />}
    filter={{ Clan_id: props.clan.id }}
  >
    <Datagrid rowClick='show'>
      <UrlField source='screenshot' />
      <ReferenceField label='Task' source='Task_id' reference='Tasks'>
        <TextField source='name' />
      </ReferenceField>
      <ReferenceField label='Member' source='Member_id' reference='members'>
        <TextField source='username' />
      </ReferenceField>
      <BooleanField source='approved' />
      <DateField source='date' />
      <ReferenceField label='Admin' source='admin' reference='members'>
        <TextField source='username' />
      </ReferenceField>
    </Datagrid>
  </List>
);

const ShowActions = ({ basePath, data }) => (
  <TopToolbar>
    <EditButton basePath={basePath} record={data} />
    <ApproveButton basePath={basePath} record={data} />
    <DenyButton basePath={basePath} record={data} />
  </TopToolbar>
);

export const TaskCompletedShow = (props) => (
  <Show {...props} actions={<ShowActions />}>
    <TabbedShowLayout>
      <Tab label='TaskCompleted'>
        <UrlField source='screenshot' />
        <ReferenceField label='Task' source='Task_id' reference='Tasks'>
          <TextField source='name' />
        </ReferenceField>
        <ReferenceField label='Description' source='Task_id' reference='Tasks'>
          <TextField source='description' />
        </ReferenceField>
        <ReferenceField label='Member' source='Member_id' reference='members'>
          <TextField source='username' />
        </ReferenceField>
        <BooleanField source='approved' />
        <DateField source='date' />
        <ReferenceField label='Admin' source='admin' reference='members'>
          <TextField source='username' />
        </ReferenceField>
      </Tab>
    </TabbedShowLayout>
  </Show>
);

export const TaskCompletedEdit = (props) => (
  <Edit {...props} undoable={false}>
    <SimpleForm>
      <TextInput source='screenshot' />
      <ReferenceInput
        label='Task'
        source='Task_id'
        reference='Tasks'
        filter={{ Clan_id: props.clan.id }}
      >
        <AutocompleteInput optionText='name' />
      </ReferenceInput>
      <ReferenceInput
        label='Member'
        source='Member_id'
        reference='members'
        filter={{ Clan_id: props.clan.id }}
      >
        <AutocompleteInput optionText='username' />
      </ReferenceInput>
      <DateInput source='date' />
      <BooleanInput source='approved' />
    </SimpleForm>
  </Edit>
);

export const TaskCompletedCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source='screenshot' />
      <ReferenceInput
        label='Task'
        source='Task_id'
        reference='Tasks'
        filter={{ Clan_id: props.clan.id }}
      >
        <AutocompleteInput optionText='name' />
      </ReferenceInput>
      <ReferenceInput
        label='Member'
        source='Member_id'
        reference='members'
        filter={{ Clan_id: props.clan.id }}
      >
        <AutocompleteInput optionText='username' />
      </ReferenceInput>
      <BooleanInput source='approved' />
      <DateInput source='date' defaultValue={new Date().toISOString()} />
      <NumberInput source='Clan_id' initialValue={props.clan.id} disabled />
    </SimpleForm>
  </Create>
);
